<template>
  <div>
    <div class="d-flex justify-content-between mb-1 flex-column flex-md-row">
      <div class="d-flex">
        <div class="d-flex flex-column mb-0">
          <p class="ml-1 text-4 font-weight-normal">
            {{ $t('profit') }}
          </p>
          <p class="ml-2 mb-1 text-primary font-weight-bold text-5">
            {{item.tradingInfo.profitCent}}%
          </p>
          <p class="text-primary font-weight-normal text-4 mb-2">
            $ {{item.tradingInfo.profit}}
          </p>
        </div>
        <div class="d-flex flex-column ml-3">
          <p class="mx-2 text-4 font-weight-normal">
            {{ $t('margin') }}
          </p>
          <p class="mx-1 mb-1 text-primary font-weight-bold text-4">
            {{item.tradingInfo.marginLevel}}%
          </p>
        </div>
      </div>

      <div class="mt-2 mr-1 mb-md-3">
        <div class="col-7"></div>
        <div class="">
          <p class="mb-1 font-weight-bold font-weight-bold text-5"
             :style="{ color: greenOrRed(item.tradingInfo.profitDay) }"
          >
            $ {{ ((item.tradingInfo.profitDay > 0) ? '+' : '') + item.tradingInfo.profitDay}}
            <span class="font-weight-light">
              ({{item.tradingInfo.profitDayCent}}%)
            </span>
          </p>
          <p class="text-3 mb-1 font-weight-light">
            {{ $t('week') }}
            <span class="text-3"
                  :style="{ color: greenOrRed(item.tradingInfo.profitWeek) }"
            >
              $ {{ ((item.tradingInfo.profitWeek > 0) ? '+' : '') + item.tradingInfo.profitWeek}}
            ({{item.tradingInfo.profitWeekCent}}%)
            </span>
          </p>
          <p class="text-3 mb-0 font-weight-light">
            {{ $t('month') }}
            <span class="text-3"
                  :style="{ color: greenOrRed(item.tradingInfo.profitMonth) }"
            >
              $ {{ ((item.tradingInfo.profitMonth > 0) ? '+' : '') + item.tradingInfo.profitMonth}}
            ({{item.tradingInfo.profitMonthCent}}%)
            </span>
          </p>
        </div>
      </div>
    </div>

    <div class="row mb-0">
      <p class="col rounded-left bg-indigo mb-1 text-white text-4 font-weight-bold">
        $ {{item.tradingInfo.balance}}
      </p>
      <p class="col rounded-right bg-blue mb-1 text-white text-4 font-weight-bold">
        $ {{item.tradingInfo.equity}}
      </p>
    </div>

    <div class="row mt-3 mb-0"
         v-show="item.tradingInfo.ordersInfo.length != 0">
      <OrderInfo v-for="item_ in item.tradingInfo.ordersInfo"
                 :order="item_"
      />
    </div>
  </div>
</template>

<script>
import OrderInfo from './orderInfo/OrderInfo'

export default {
  name: 'CardAdvanced',
  components: {
    OrderInfo,
  },
  props: [
    'item',
  ],

  methods: {
    greenOrRed (i) {
      return ((i > 0) ? '#35bd77' : (i == 0) ? '' : 'red')
    },
  },

  i18n: {
    messages: {
      'en': {
        'profit': 'profit',
        'margin': 'margin',
        'week': 'week',
        'month': 'month',
      },
      'ru': {
        'profit': 'просадка',
        'margin': 'маржа',
        'week': 'неделя',
        'month': 'месяц',
      },
    },
  },
}
</script>

<style lang="scss" scoped>

</style>
