<template>
  <div class="home">
    <div class="mt-5 d-flex justify-content-center align-center">
      <h1>
        {{ group.title }}
      </h1>
    </div>

    <b-form-select v-model="mode"
                   :options="itemsSelect"
                   class="mt-3"
                   @input="$router.push({ path: $route.path, query: { 'mode': mode }})"
    />

    <div class="container jumbotron pt-4 pb-4 mt-3 d-flex flex-column justify-content-start align-content-start"
         v-if="mode == 'summary'"
    >
      <div class="row justify-content-between ml-2 mr-1">
        <div class="d-flex flex-column">
          <div class="text-left text-4 mb-1">
            {{ $t('BALANCE') }}
            <span class="text-primary">
              {{ Balance.toFixed(2) }} $
            </span>
          </div>
          <div class="text-left mt-1 text-4 mb-1">
            {{ $t('Equity') }}
            <span class="text-primary">
              {{ Equity.toFixed(2) }} $
            </span>
          </div>
          <div class="text-left mt-1 text-3 mb-3">
            {{ $t('Today') }}
            <span class="text-primary">
              {{ Today.toFixed(2) }} $
            </span>
          </div>
        </div>
        <div class="text-2 mt-1">
          {{ lastTime }}
        </div>
      </div>

      <b-table striped
               hover
               :items="itemsTable"
               :fields="fields"
               head-variant="dark"
               outlined
               bordered
      />
    </div>

    <div class="d-flex flex-column flex-md-row flex-wrap justify-content-between m-0 ml-md-3 p-0 mt-3"
         v-if="mode == 'advanced'"
    >
      <Card class="mx-1 col-12 col-md-5_"
            v-for="robot in robots"
            :item="robot"
            :lastTime="lastTime"
            :not_setting="not_setting"
      >
        <template #content>
          <CardAdvanced :item="robot"/>
        </template>
      </Card>
    </div>
  </div>
</template>

<script>
import Card from '../robot/Card'
import CardAdvanced from '../robot/CardAdvanced'
import { mapActions } from 'vuex'

export default {
  name: 'GroupItem',
  components: {
    Card,
    CardAdvanced,
  },

  data () {
    return {
      mode: 'summary',
    }
  },

  props: {
    group: Object,
    robots: Array,
    time: Number,
    lastTime: String,
    not_setting: Boolean,
  },

  watch: {
    mode (value) {
      setTimeout(() => {
        this.setLoading(false)
      }, 200)
    },
  },

  computed: {
    Balance () {
      return this.robots.reduce((value, item) => {
        return value + item.tradingInfo.balance
      }, 0)
    },

    Equity () {
      return this.robots.reduce((value, item) => {
        return value + item.tradingInfo.equity
      }, 0)
    },

    Today () {
      return this.robots.reduce((value, item) => {
        return value + item.tradingInfo.profitDay
      }, 0)
    },

    itemsSelect () {
      return [
        {
          value: 'summary',
          text: this.$t('Summary'),
        },
        {
          value: 'advanced',
          text: this.$t('Advanced mode'),
        },
      ]
    },

    fields () {
      return [
        { key: 'scope', label: this.$t('scope') },
        { key: 'profit', label: this.$t('profit') },
        { key: 'margin', label: this.$t('margin') },
        { key: 'profitDay', label: this.$t('profitDay') },
      ]
    },

    itemsTable () {
      return this.robots.map((item) => {
        return {
          isActive: true,
          scope: item.title,
          profit: item.tradingInfo.profit + ' $',
          margin: item.tradingInfo.marginLevel + '%',
          profitDay: item.tradingInfo.profitDay + ' $',
        }
      })
    },
  },

  methods: {
    ...mapActions('general', [
      'setLoading',
    ]),
  },

  async created () {
    if (this.$route.query.mode) {
      this.mode = this.$route.query.mode
    }
  },
}
</script>

<style lang="scss" scoped>
.home {
  overflow-y: auto;
  overflow-x: hidden;
  padding-right: 25px;
  padding-left: 25px;
}
</style>
