<template>
  <GroupItem :group="group"
             :robots="robots"
             :time="time"
             :lastTime="lastTime"
  />
</template>

<script>
import GroupItem from '../../components/group/Group'
import { mapActions, mapState } from 'vuex'

export default {
  name: 'Group',
  components: {
    GroupItem,
  },
  data () {
    return {
      id: '',
      group: {},
      time: new Date().getTime(),
      lastTime: '',
    }
  },

  methods: {
    ...mapActions('groups', [
      'getGroups',
      'getGroup',
    ]),

    ...mapActions('robot', [
      'getRobotsByGroup',
    ]),
    ...mapActions('general', [
      'setLoading',
    ]),

    setLastTime () {
      const time_ = new Date(new Date().getTime() - this.time)
      if (time_.getTime() / 1000 == 0) {
        this.lastTime = this.$t('Last Update: now')
      }
      else if (time_.getTime() / 1000 >= 20) {
        this.lastTime = this.$t('Last Update') + ': ' + new Date(this.time).toLocaleString()
      } else {
        this.lastTime = this.$t('Last Update') + ': ' + Math.round(parseInt(time_.getTime()) / 1000) + ' ' + this.$t('second age')
      }
    },
  },

  computed: {
    ...mapState('groups', [
      'groups',
    ]),
    ...mapState('robot', [
      'robots',
    ]),
  },

  async created () {
    this.id = this.$route.params.id
    this.group = await this.getGroup(this.id)

    this.getRobotsByGroup(this.id)
    this.time = new Date().getTime()

    this.intervalGetRobots = setInterval(async () => {
      this.getRobotsByGroup(this.id)
      this.time = new Date().getTime()
    }, 10000)

    this.setLastTime()
    this.intervalLastTime = setInterval(() => {
      this.setLastTime()
    }, 4000)
    this.setLoading(false)
  },

  beforeDestroy () {
    if (this.intervalGetRobots) clearInterval(this.intervalGetRobots)
    if (this.intervalLastTime) clearInterval(this.intervalLastTime)
  },
}
</script>
