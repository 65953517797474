const state = {
  mouseDownCoordinates: null,
}

const mutations = {
  SET_MOUSE_DOWN_COORDINATES (state, coordinates) {
    state.mouseDownCoordinates = coordinates
  },
}

export default {
  namespaced: true,
  state,
  mutations,
}
