import Vue from 'vue'
import Vuex from 'vuex'

// 1 Общие - глобальные
import general from './general'
import auth from './auth'
import user from './user'
import billing from './billing'
import bonus from './bonus'
import services from './services'

// 2 страница роботов
import robot from './robot'

// 3 страница групп
import groups from './groups'

import advisors from './advisors'

// 4 страница публичных компонентов
import publicKey from './publicKey'
//-----------------------------------

import telegram from './telegram'

// 5 Общие - прочие
import ui from './ui'
import tour from './tour'
import notification from './notification'
import chart from './chart'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    general,
    auth,
    user,
    billing,
    bonus,
    services,

    robot,

    groups,
    publicKey,

    advisors,

    telegram,

    ui,
    tour,
    notification,
    chart,
  },
})
