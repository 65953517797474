<template>
  <div class="container jumbotron mt-0 pt-2 shadow-sm pl-3 py-2 height-70px">
    <div class="d-flex flex-sm-row flex-lg-row justify-content-between align-content-center mb-1 h-100">

      <div class="d-flex">
        <div class="rectangle"
             :class="[ activeColor ]"
        />

        <div class="pl-4 pt-1 d-flex flex-column justify-content-between">
          <div class="text-left text-3 font-italic">
            {{ $t('If') + ' ' + $t(item.parameter) + ' ' + $t(item.direction) + ' ' + item.value + $t(', and with each step') + ' ' + item.step }}
          </div>
          <div class="d-flex pt-1">
            <div class="icon"
                 v-show="item.tg == '1'"
            >
              <TelegramIcon />
            </div>

            <div class="icon"
                 v-show="item.email == '1'"
            >
              <EmailIcon />
            </div>
          </div>
        </div>
      </div>

      <div class="d-flex pl-1 pl-md-2 pl-lg-0">
        <div class="vl pr-4"/>
        <ContextMenu :id="'contextMenuNotify  ' + item.id"
                     class="pt-3 pl-2"
        >
          <template  #context-menu-content>
            <ContextMenuNotify @showModalSetting="showModal = true"
                               @deleteNotify="deleteNotify(item.id)"
            />
          </template>
        </ContextMenu>
      </div>
    </div>

    <SettingNotify
      :showModal.sync="showModal"
      :label="$t('Edit notify')"
      :labelOK="$t('Save')"
      :create="false"
      :item="item"
    />
  </div>
</template>

<script>
import ContextMenuNotify from './menu/ContextMenuNotify'
import ContextMenu from '../ui/ContextMenu'
import SettingNotify from '@/components/notifications/setting/SettingNotify'

import TelegramIcon from '@/assets/icons/telegram.svg'
import EmailIcon from '@/assets/icons/email.svg'
import { mapActions } from 'vuex'

export default {
  name: 'Notify',
  components: {
    ContextMenuNotify,
    ContextMenu,
    SettingNotify,

    TelegramIcon,
    EmailIcon,
  },

  props: [
    'item',
  ],

  data () {
    return {
      showModal: false,
    }
  },

  computed: {
    activeColor () {
      return 'bgM-' + ((this.item.status == 'Активный') ? 'active' : 'inactive')
    },
  },

  methods: {
    ...mapActions('notification', [
      'deleteNotify',
    ]),
  },

  i18n: {
    messages: {
      'en': {
      },
      'ru': {
        'If': 'Если',
        'balance': 'Баланс',
        'margin': 'Уровень маржи',
        'equity': 'Средства',
        'profit': 'Просадка',
        'count_open_orders': 'Число открытых ордеров',
        'profit_day': 'Прибыль за день',
        'more': 'Больше',
        'less': 'Меньше',
        ', and with each step': ', и с каждым шагом',
        'Edit notify': 'Редактировать уведомление',
      },
    },
  },
}
</script>

<style lang="scss" scoped>
.height-70px {
  position: relative;
}
@media (min-width: 700px) {
  .height-70px {
    height: 70px;
  }
}
.rectangle {
  width: 5px;
  height: auto;
}

.bgM-active {
  background-color: #3EA54F;
}

.bgM-inactive {
  background-color: #D9C300;
}

.icon {
   width: 20px;
   display: flex;
   justify-content: center;
   align-items: center;
   margin-right: 10px;

  svg {
    width: 20px;
    height: 20px;
    /*fill: $grey;*/
    display: block;
  }
}

.vl {
  border-left: 0.5px solid silver;
  height: auto;
  margin-top: 10px;
  margin-bottom: 10px;
}
</style>
