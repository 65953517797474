import Vue from 'vue'
import axios from 'axios'
import App from './App.vue'
import router from './router/router'
import store from './store/store'
import VueAxios from 'vue-axios'
import { apiUrl, appUrl } from './apiConfig'
import i18n from './i18n'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import VueTheMask from 'maska'
import bootstrapVue from 'bootstrap-vue'

Vue.config.productionTip = false

Vue.use(VueAxios, axios)
Vue.use(bootstrapVue)
Vue.use(VueTheMask)

axios.defaults.withCredentials = true
axios.defaults.baseURL = apiUrl
axios.defaults.baseURL_app = appUrl

router.beforeEach((to, from, next) => {
  switch (to.name) {
    case 'Home':
      next('/robots/Index')
      break
    case 'Index':
      store.dispatch('auth/isAuth').then(res => {
        return (!res.data.success)
          ? next('/sign-in')
          : next()
      })
      break
    case 'Robots':
      store.dispatch('auth/isAuth').then(res => {
        return (!res.data.success)
          ? next('/sign-in')
          : next()
      })
      break
    case 'sign-in':
    case 'sign-up':
    case 'reset-password':
    case 'change-password':
      store.dispatch('auth/isAuth').then(res => {
        return (res.data.success)
          ? next('/sign-in')
          : next()
      })
      break
    default:
      return next()
  }
})

Vue.directive('click-outside', {
  bind: function (el, binding, vnode) {
    el.clickOutsideEvent = function (event) {
      if (!(el == event.target || el.contains(event.target))) {
        vnode.context[binding.expression](event)
      }
    }
    document.body.addEventListener('click', el.clickOutsideEvent)
  },

  unbind: function (el) {
    document.body.removeEventListener('click', el.clickOutsideEvent)
  },
})

new Vue({
  axios,
  router,
  store,
  i18n,
  render: h => h(App),
}).$mount('#app')
