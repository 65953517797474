// import { useKriptid } from '@/utils/helpers'
import { requestTelegramURL } from '@/requestManager/TelegramRequests'

const state = {
  data: '',
  isTabActive: true,
  timezone: null,
}

const getters = {
  hasCompletedTip: state => data => {
    return ''
  },
}

const mutations = {
  SET_TELEGRAM_URL_DATA (state, data) {
    state.data = data
  },

}

const actions = {
  async getTelegramURL ({ state, commit, dispatch }) {
    const response = await requestTelegramURL()

    if ((response.data.success)) {
      commit('SET_TELEGRAM_URL_DATA', response.data.data)
    }

    return response.data.data
  },
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
}
