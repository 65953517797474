import axios from 'axios'
import i18n from '@/i18n'

const state = {
  no_image: '/img/avatar.png',
  timezones: null,
  language: 'en',
  openedAppNav: true,
  loading: true,
  abilityToDragscroll: false,
}

const getters = {
  getCurrentLangCode (state) {
    return state.language.split('-')[0]
  },
}

const mutations = {
  TOGGLE_APP_NAV (state, value) {
    state.openedAppNav = value
  },
  SET_LOADING (state, value) {
    state.loading = value
  },

  SET_TIMEZONES (state, timezones) {
    state.timezones = timezones
  },
  SET_LANGUAGE (state, language) {
    state.language = language
  },
  SET_ABILITY_TO_DRAGSCROLL (state, bool) {
    state.abilityToDragscroll = bool
  },
}

const actions = {
  toggleAppNav ({ commit }, value) {
    commit('TOGGLE_APP_NAV', value)
    localStorage.openedAppNav = value
  },
  setLoading ({ commit }, value) {
    commit('SET_LOADING', value)
  },
  setLanguage ({ commit }, language) {
    i18n.locale = language
    commit('SET_LANGUAGE', language)
    localStorage.setItem('language', language)
  },
  setLanguageFromStorage ({ dispatch }) {
    const language = localStorage.getItem('language')
    dispatch('setLanguage', language)
  },
  async getTimeZones ({ commit }) {
    const response = await axios.get('user/profile/timezone-list')

    if (response.data.success) {
      commit('SET_TIMEZONES', response.data.timezones)
    }
  },
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
}
