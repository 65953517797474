import axios from 'axios'

const state = {
  bonus: null,
}

const mutations = {
  SET_BONUS (state, bonus) {
    state.bonus = bonus
  },
}

const actions = {
  // Получение бонуса, если он есть
  async getBonus ({ commit }, code = '') {
    const params = { code }
    const { data } = await axios.get('bonus/gift-pro-account/get-active-bonus', { params })
    const { success, bonus } = data

    if (success) {
      commit('SET_BONUS', bonus)
    }

    return success
  },

  // Активация бонуса
  async activateBonus ({ commit }, requestData) {
    const { data } = await axios.post('bonus/gift-pro-account/activate-bonus', requestData)
    const { success } = data

    if (success) {
      commit('SET_BONUS', null)
    }

    return success
  },
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
}
