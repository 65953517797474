<template>
  <div>
    <b-table striped
             hover
             :items="itemsTable"
             :fields="fields"
             head-variant="dark"
             outlined
             bordered
    />

    <div class="d-flex justify-content-end text-2 mt-1">
      {{ lastTime }}
    </div>
  </div>
</template>

<script>
export default {
  name: 'SummaryRobot',
  props: [
    'robots',
    'time',
    'lastTime',
  ],

  computed: {
    fields () {
      return [
        { key: 'scope', label: this.$t('scope') },
        { key: 'profit', label: this.$t('profit') },
        { key: 'margin', label: this.$t('margin') },
        { key: 'profitDay', label: this.$t('profitDay') },
      ]
    },

    itemsTable () {
      return this.robots.map((item) => {
        return {
          isActive: true,
          scope: item.title,
          profit: item.tradingInfo.profit + ' $',
          margin: item.tradingInfo.marginLevel + '%',
          profitDay: item.tradingInfo.profitDay + ' $',
        }
      })
    },
  },
}
</script>

<style scoped>

</style>
