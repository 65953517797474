<template>
    <a :href="'https://ru.tradingview.com/chart?symbol=FX:' + order.symbol"
       target="_blank"
       class="col-1.5 ml-2 mt-1 p-0 jumbotron text_center orderInfo cursor-pointer"
      :class="orderClass"
    >
      <div>
        <p class="row m-1 mx-2 text-3">
          {{order.symbol}}
        </p>
        <p class="row m-1 mx-2 text-3"
           :class="{ 'text-white': yellow }"
        >
          {{order.price}}
        </p>
        <p class="row m-1 mx-2 text-3"
           v-if="order.hasOwnProperty('profitBuy')"
           :class="{ 'text-white': yellow }"
        >
          {{order.profitBuy.toFixed(2)}} $
        </p>
        <p class="row m-1 mx-2 text-3"
           v-if="order.hasOwnProperty('profitSell')"
           :class="{ 'text-white': yellow }"
        >
          {{order.profitSell.toFixed(2)}} $
        </p>
        <p class="row m-0 bg-black text-3 text-white"
           v-if="order.hasOwnProperty('volumeBuy')"
           :class="{ 'text-white': yellow }"
        >
            <span class="mx-2 text-white">
              ▲<span>{{order.volumeBuy.toFixed(2)}}</span> / {{order.countOrdersBuy}}
            </span>
        </p>
        <p class="row m-1 mx-2 text-3"
           v-if="order.hasOwnProperty('tpBuy')"
           :class="{ 'text-white': yellow }"
        >
          {{order.tpBuy}} ({{order.tpLengthBuy}} п)
        </p>
        <p class="row m-0 bg-black text-3"
           v-if="order.hasOwnProperty('volumeSell')"
           :class="{ 'text-white': yellow }"
        >
            <span class="mx-2 text-white">
              ▼<span>{{order.volumeSell.toFixed(2)}}</span> / {{order.countOrdersSell}}
            </span>
        </p>
        <p class="row m-1 mx-2 text-3"
           v-if="order.hasOwnProperty('tpSell')"
           :class="{ 'text-white': yellow }"
        >
          {{order.tpSell}} ({{order.tpLengthSell}} п)
        </p>
      </div>
    </a>
</template>

<script>
export default {
  name: 'OrderInfo',
  props: [
    'order',
  ],
  data () {
    return {
      yellow: !((this.order.typeBuy == 'Buy') && (this.order.typeSell == 'Sell')),
    }
  },

  computed: {
    orderClass: function () {
      const Buy = this.order.typeBuy == 'Buy'
      const Sell = this.order.typeSell == 'Sell'
      return {
        'bg-green': (Buy && !Sell),
        'bg-red': (!Buy && Sell),
        'bg-yellow': (Buy && Sell),
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.orderInfo {
  height: 100%;
  color: #2c3e50;
}
a {
  &:hover {
    text-decoration: none !important;
    p:first-child {
      text-decoration: underline;
    }
  }
}
</style>
