<template>
  <div class="v-auth-container jumbotron shadow-sm pt-4 pb-4 bg-light">
    <slot />
  </div>
</template>

<script>
export default {
  name: 'VAuthContainer',
}
</script>

<style lang="scss" scoped>
.v-auth-container {
  background-color: #ffffff !important;
}
</style>
