const state = {
  examples: [],
  currentExample: 0,
  currentTourName: null,
  show: false,
}

const getters = {
  viewedExample: state => state.examples[state.currentExample],
  currentExampleIsLast: state => state.currentExample === state.examples.length - 1,
}

const mutations = {
  NEXT_EXAMPLE (state) {
    state.currentExample++
  },
  PREV_EXAMPLE (state) {
    state.currentExample--
  },

  SHOW_TOUR (state) {
    state.show = true
  },
  HIDE_TOUR (state) {
    state.show = false
    state.examples = []
    state.currentExample = 0
  },

  ADD_EXAMPLE (state, example) {
    // если такая подсказка уже встречалась - не будем её показывать
    if (state.examples.find(loopExample =>
      loopExample.tourName === example.tourName &&
      loopExample.text === example.text)) {
      return
    }

    // если tourName отсутствует - не добавляем
    if (example.tourName === null) return

    state.examples.push(example)
  },

  SET_CURRENT_TOUR (state, name) {
    state.currentTourName = name
  },
}

const actions = {
  nextExample ({ commit, getters }) {
    if (!getters.currentExampleIsLast) {
      commit('NEXT_EXAMPLE')
    } else {
      commit('HIDE_TOUR')
    }
  },
  // TODO: убрать избыточный экшен
  prevExample ({ commit }) {
    commit('PREV_EXAMPLE')
  },
  showTour ({ commit }, name) {
    commit('SHOW_TOUR')
    commit('SET_CURRENT_TOUR', name)
  },
  // TODO: убрать избыточный экшен
  hideTour ({ commit }) {
    commit('HIDE_TOUR')
  },
  // TODO: убрать избыточный экшен
  addExample ({ commit }, example) {
    commit('ADD_EXAMPLE', example)
  },
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
}
