<template>
  <router-link
      :to="{ name: to }"
      :class="classesForParagraph"
  >
    <slot/>
  </router-link>
</template>

<script>
export default {
  name: 'VLink',

  props: {
    to: {
      type: String,
      default: null,
    },
    color: {
      type: String,
      default: 'grey',
      validator (val) {
        return [
          'grey',
          'blue',
        ].indexOf(val) !== -1
      },
    },
    outlined: {
      type: Boolean,
      default: false,
    },
  },
  data () {
    return {
      classesForParagraph: {
        'v-link': true,
        'v-link_grey': this.color === 'grey',
        'v-link_blue': this.color === 'blue',
        'v-link_outlined-grey': this.outlined && this.color === 'grey',
        'v-link_outlined-blue': this.outlined && this.color === 'blue',
      },
    }
  },
}
</script>

<style lang="scss" scoped>
a {
  text-decoration: none;
  border: none;
  outline: none;
}

.v-link {
  display: inline-block;
  height: max-content;
  font-size: 14px;
  font-variation-settings: 'wght' 600;
  line-height: 19px;
  cursor: pointer;
  transition: all .3s ease;
}

.v-link_grey {
  color: #A6ACBF;

  &:hover {
    color: #7B8193;
  }

  &:active {
    color: #4D505E;
  }
}

.v-link_blue {
  color: #9CB1F7;

  &:hover {
    color: #6788F3;
  }

  &:active {
    color: #5B78D6;
  }
}

.v-link_outlined-grey {
  border-bottom: 2px solid #E3E4E8;

  &:hover {
    border-color: #A6ACBF;
  }

  &:active {
    border-color: #7B8193;
  }
}

.v-link_outlined-blue {
  border-bottom: 2px solid #D9E1FC;

  &:hover {
    border-color: #9CB1F7;
  }

  &:active {
    border-color: #5B78D6;
  }
}
</style>
