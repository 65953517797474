import { useKriptid } from '@/utils/helpers'
import axios from 'axios'
import router from '@/router/router'

const codeAuth = '12345676'

const state = {
  loggedIn: false,
  token: null,
  email: null,
  mailLink: '',
  boolValueEmailSent: false,
  //telegram
  registered: false,
  telegramId: null,
}

const mutations = {
  SET_SIGN_IN_SUCCESS (state, telegram) {
    state.loggedIn = true
    state.registered = telegram.registered
    state.telegramId = telegram.telegramId
  },
  LOGOUT (state) {
    state.loggedIn = false
  },

  SET_EMAIL (state, email) {
    state.email = email
  },
  SET_EMAIL_LINK (state, mailLink) {
    state.mailLink = mailLink
  },
  SET_EMAIL_SEND_FLAG (state, bool) {
    state.boolValueEmailSent = bool
  },
}

const getters = {}

const actions = {
  async resetToken (context, data) {
    return await axios.post('/user/reset/request-token', data)
  },
  async changePassword (context, data) {
    return await axios.post('/user/reset/password', data)
  },

  async signIn (context, data) {
    return await axios.post('/api/auth/sign-in', data)
  },
  async signUp (context, data) {
    if (data.code == codeAuth) {
      return await axios.post('/api/user/sign-up', data)
    }
  },
  signInSuccess ({ commit }, data) {
    useKriptid(1, 'event', 'subLogin')

    commit('SET_SIGN_IN_SUCCESS', data.telegram)
    const name = localStorage.getItem('prevRoute')

    if (name) {
      localStorage.removeItem('prevRoute')
      router.push(name)
    } else {
      router.push({ name: 'Index' })
    }
  },
  async logout ({ dispatch, commit }) {
    const response = await axios.post('api/auth/sign-out')
    commit('LOGOUT')

    dispatch('workspace/setWorkspace', {
      workspace_id: null,
    }, { root: true })

    router.push('/sign-in')
    return response
  },

  setAxiosToken () {
    axios.defaults.headers.common = {
      Authorization: `Bearer ${JSON.parse(localStorage.getItem('token')).access_token}`,
    }
  },
  async isAuth () {
    // let token = JSON.parse(localStorage.getItem('token'))
    // // STATUSES
    // // 1 - TOKEN IS GOOD
    // // 2 - TOKEN IS OVERDUE
    // // 3 - NOT HAVE TOKEN
    // let status = 3
    // if (token) {
    //     if (token.access_token) {
    //         if (token.expires_at*1000 <= new Date()) {
    //             localStorage.removeItem('token')
    //             status = 3
    //         } else {
    //             status = 1
    //         }
    //     }
    // }
    // return status
    const response = await axios.get('api/auth/is-auth')
    return response
  },
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
}
