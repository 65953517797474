<template>
  <div class="context-menu">
    <div class="context-menu__wrapper"
         @click.stop="openDropdown"
    >
      <div class="context-menu__cmn">
        <div class="context-menu__name context-menu__three">
          ...
        </div>
      </div>
    </div>

    <div v-if="dropdown"
      v-click-outside="closeDropdown"
      class="dropdown"
    >
      <slot name="context-menu-content"/>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ContextMenu',

  data () {
    return {
      dropdown: false,
    }
  },

  methods: {
    openDropdown(e) {
      this.dropdown = true
      this.left = e.x + 'px';
      this.top = e.y + 'px';
    },
    closeDropdown() {
      this.dropdown = false
    },
  },
}
</script>

<style lang="scss" scoped>
.context-menu {
  margin-right: -10px;
  margin-top: -5px;

  &__wrapper {
    display: flex;
    align-items: center;
    text-decoration: none;
    color: $dark;
    position: relative;
    cursor: pointer;
  }

  .dropdown {
    position: absolute;
    border-radius: 4px;
    background: #fff;
    box-shadow: 0 0 13px 0 rgba(48, 48, 48, 0.27);
    width: 185px;
    top: 10px;
    right: 20px;
    margin-top: 5px;
    cursor: default;

    ul {
      padding: 10px 0;
      margin: 0;
      border-bottom: 1px solid #f0f0f0;

      li {
        list-style: none;

        .link {
          padding: 0 15px;
          font-size: 14px;
          display: flex;
          align-items: center;
          height: 33px;
          cursor: pointer;
          text-decoration: none;
          color: #2c2c2c;
          $root: &;

          &:hover {
            background: #f0f0f0;

            svg {
              path {
                &:nth-child(2) {
                  fill: $blue
                }

                &:nth-child(1) {
                  fill: $lightBlue;
                }
              }
            }
          }

          &__icon {
            width: 13px;
            display: flex;
            justify-content: center;
            align-items: center;
            margin-right: 10px;

            svg {
              width: 13px;
              height: 13px;
              fill: $grey;
              display: block;
            }
          }

          &__wrapper {
            display: flex;
            justify-content: space-between;
            align-items: center;
            flex: 1 1 auto;
          }
        }
      }
    }
  }

  &__cmn {
    flex: none;
    margin-right: 10px;
  }

  &__name {
    font-size: rem(14px);
    font-weight: 500;
    text-align: right;
  }

  &__email {
    color: #8d8d8d;
    font-size: 10px;
    // font-weight: 300;
  }

  &__settings {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    color: #768082;
    font-size: rem(12px);
    margin-top: 2px;
  }

  &__icon {
    width: 10px;
    height: 10px;
    margin-right: 5px;
    flex: none;

    svg {
      fill: #768082;
      width: 100%;
      display: block;
    }
  }

  &__ava {
    border: 1px solid rgb(223, 223, 223);
    background: #fff;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    flex: none;

    img {
      display: block;
      width: 100%;
      border-radius: 50%;
    }
  }

  &__three {
    transform: rotate(90deg);
    font-weight: 700;
    font-size: 17px;
  }
}
</style>
