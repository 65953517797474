<template>
  <div class="py-2 mt-5 ml-4 home">
    <h1 class="text-left">
      {{ $t('Setting up Scores Notifications') }}
    </h1>
    <div class="d-flex align-content-start mb-3">
      <b-button class="mx-3 mt-3"
                variant="primary"
                v-on:click="showModal = true"
      >
        {{ $t('Create') }}
      </b-button>
    </div>

      <div class="d-flex flex-column align-items-start justify-content-start m-0 p-0 pt-3 col-750px">
        <Notify v-for="notify in notifications"
                :item="notify"
        />
      </div>

    <SettingNotify
      :showModal.sync="showModal"
      :label="$t('Create notify')"
      :labelOK="$t('Create')"
      :create="true"
    />
  </div>
</template>

<script>
import SettingNotify from '@/components/notifications/setting/SettingNotify'
import Notify from '@/components/notifications/Notify'
import { mapActions, mapState } from 'vuex'

export default {
  name: 'notifications',
  components: {
    Notify,
    SettingNotify,
  },

  data () {
    return {
      showModal: false,
    }
  },

  computed: {
    ...mapState('notification', [
      'notifications',
    ]),
  },

  methods: {
    ...mapActions('notification', [
      'getNotifications',
    ]),
    ...mapActions('general', [
      'setLoading',
    ]),
  },

  created: async function () {
    setTimeout(() => {
      this.setLoading(false)
    }, 1000)
    await this.getNotifications()
  },

  i18n: {
    messages: {
      'en': {
        'Setting up Scores Notifications': 'Setting up Scores Notifications',
      },
      'ru': {
        'Create notify': 'Создать уведомление',
        'Setting up Scores Notifications': 'Настройка уведомлений счетов',
        'Create': 'Создать',
      },
    },
  },
}
</script>

<style scoped>
.home {
  overflow-y: auto;
}

@media (min-width: 1200px) {
  .col-750px {
    width: 750px;
  }
}
</style>
