<template>
  <div class="v-select">
    <label v-if="label" class="v-select__label">
      {{ label }}
    </label>

    <div class="v-select__item" v-click-outside="closeDropdownVisible">
      <div
        class="v-select__selected"
        @click="toggleDropdownVisible"
      >
        <div class="v-select__selected-text">
          {{ $t(resultSelectedItem) }}
        </div>

        <IconArrow :class="[
          'v-select__selected-icon',
          { 'v-select__selected-icon_dropdown-visible': dropdownIsVisible },
        ]" />
      </div>

      <div
        v-if="dropdownIsVisible"
        class="v-select__dropdown"
      >
        <div class="v-select__dropdown-items">
          <div
            v-for="dropdownItem in dropdownItems"
            :key="dropdownItem"
            class="v-select__dropdown-item"
            @click.stop="selectDropdownItem(dropdownItem)"
          >
            {{ $t(dropdownItem) }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import IconArrow from '@/assets/icons/arrowone.svg';

export default {
  name: 'VSelect',
  components: {
    IconArrow,
  },

  props: {
    label: {
      type: String,
      default: null,
    },
    placeholder: {
      type: String,
      default: null,
    },
    dropdownItems: {
      type: Array,
      required: true,
    },
    defaultSelectedItem: {
      type: [String, Number],
      default: null,
    },
  },
  data: () => ({
    selectedItem: '',
    dropdownIsVisible: false,
  }),
  computed: {
    resultSelectedItem() {
      return this.placeholder
        || this.selectedItem
        || this.dropdownItems[0];
    },
  },

  methods: {
    setDefaultSelectedItem() {
      this.selectedItem = this.defaultSelectedItem || '';
    },
    toggleDropdownVisible () {
      this.dropdownIsVisible = !this.dropdownIsVisible;
    },
    closeDropdownVisible () {
      if (this.dropdownIsVisible) {
        this.dropdownIsVisible = false;
      }
    },
    selectDropdownItem(selectedItem) {
      this.selectedItem = selectedItem;
      this.$emit('select-dropdown-item', selectedItem);

      this.toggleDropdownVisible();
    },
  },

  created() {
    this.setDefaultSelectedItem();
  },

  i18n: {
    messages: {
      'en': {
        'Daily': 'Daily',
        'Weekly': 'Weekly',
        'Monthly': 'Monthly',
        'Yearly': 'Yearly',
        'Custom': 'Custom',
        'By day': 'By day',
        'By week': 'By week',
        'By month': 'By month',
        'By year': 'By year',
        'Indefinitely': 'Indefinitely',
        'To date': 'To date',
        'Repeat to certain date': 'Repeat to certain date',
        'Every': 'Every',
        'Several times': 'Several times',
        'days': 'days',
        'weeks': 'weeks',
        'months': 'months',
        'years': 'years'
      },
      'ru': {
        'Daily': 'Ежедневно',
        'Weekly': 'Еженедельно',
        'Monthly': 'Ежемесячно',
        'Yearly': 'Ежегодно',
        'Custom': 'Настроить',
        'By day': 'По дням',
        'By week': 'По неделям',
        'By month': 'По месяцам',
        'By year': 'По годам',
        'Indefinitely': 'Бессрочно',
        'To date': 'До даты',
        'Repeat to certain date': 'Повторять до даты',
        'Every': 'Каждые',
        'Several times': 'Несколько раз',
        'days': 'дней',
        'weeks': 'недели',
        'months': 'месяцев',
        'years': 'лет'
      },
    },
  },
}
</script>

<style lang="scss">
// @TODO: убрать в будущем, когда не будет глобальных стилей для select
.v-select {
  border: none;

  @include dark-theme {
    border: none;
    background: none;
  }
}

.v-select__dropdown {
  margin-top: 0;
}
</style>

<style lang="scss" scoped>
.v-select {
  display: grid;
  grid-row-gap: 4px;
}

.v-select__label {
  font-size: 12px;
  font-weight: 500;
  line-height: 14px;
}

.v-select__item {
  position: relative;
}

.v-select__selected {
  display: grid;
  grid-template-columns: 1fr max-content;
  grid-column-gap: 10px;
  border: 1px solid $borderColor;
  border-radius: 4px;

  @include dark-theme {
    background-color: $darkThemeBgTwo;
    border-color: #2B2C30;
  }
}

.v-select__selected-icon {
  transform: rotate(90deg);
  transition: transform .2s ease;
}

.v-select__selected-icon_dropdown-visible {
  transform: rotate(-90deg);
}

.v-select__dropdown {
  position: absolute !important;
  top: 48px;
  left: 0;
  width: 100%;
  background-color: #FFF;
  box-shadow: 0 4px 13px rgba(48, 48, 48, .1);
  border-radius: 4px;

  @include dark-theme {
    background-color: $darkThemeBgTwo;
  }
}

.v-select__dropdown-items {
  padding: 8px 0;
  max-height: 200px;
  overflow: hidden;
}

.v-select__dropdown-item {
  display: flex;
  align-items: center;
  padding: 0 17px;
  height: 36px;
  cursor: pointer;

  &:hover {
    background-color: $lightThemeBackground2;

    @include dark-theme {
      background-color: $darkThemeBgOne;
    }
  }
}
</style>
