// import SocialAuth from '../views/auth/SocialAuth.vue';
import SignIn from '../views/auth/SignIn.vue'
import SignUp from '../views/auth/SignUp.vue'
// import ConfirmEmail from '../views/auth/ConfirmEmail.vue';
// import ChangePassword from '../views/auth/ChangePassword.vue';
// import ResetPassword from '../views/auth/ResetPassword.vue';
// import VerifyEmail from '../views/VerifyEmail.vue';

export default [
  {
    path: '/sign-in',
    name: 'sign-in',
    meta: {
      layout: 'auth',
    },
    component: SignIn,
    props: true,
  },
  {
    path: '/sign-up',
    name: 'sign-up',
    meta: {
      layout: 'auth',
    },
    component: SignUp,
    props: true,
  },
  // {
  //   path: '/confirm-email',
  //   name: 'confirm-email',
  //   meta: {
  //     layout: 'auth',
  //   },
  //   component: ConfirmEmail,
  //   props: true,
  // },
  // {
  //   path: '/social-auth',
  //   name: 'social-auth',
  //   meta: {
  //     layout: 'auth',
  //   },
  //   component: SocialAuth,
  // },
  // {
  //   path: '/reset-password',
  //   name: 'reset-password',
  //   meta: {
  //     layout: 'auth',
  //   },
  //   component: ResetPassword,
  // },
  // {
  //   path: '/change-password',
  //   name: 'change-password',
  //   meta: {
  //     layout: 'auth',
  //   },
  //   component: ChangePassword,
  // },
  // {
  //   path: '/verify-email',
  //   name: 'verify-email',
  //   meta: {
  //     layout: 'loading',
  //   },
  //   component: VerifyEmail,
  // },
]
