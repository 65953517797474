<template>
  <Modal  :showModal="showModal"
          @update:showModal="$emit('update:showModal', $event)"
          :label="$t('Setting bot')"
  >
    <template #content>
      <div>
        <b-form>
          <b-form-group
          id="input-group-1"
          :label="$t('accountId')"
          label-for="input-accountId"
        >
          <b-form-input
            id="input-accountId"
            :value="item.accountId"
            readonly
          />
        </b-form-group>
          <b-form-group
            id="input-group-1"
            :label="$t('Name bot')"
            label-for="input-name"
          >
            <b-form-input
              id="input-name"
              v-model="title"
            />
          </b-form-group>
          <b-form-group
            id="input-group-3"
          >
            <b-form-checkbox
              v-model="publicRobot"
              class="mb-2"
              switch
              @change="getKeyRobot($event)"
            >
              {{ $t('Public group')}}
            </b-form-checkbox>
            <b-form-input v-show="publicRobot"
                          v-model="keyRobot"
                          :placeholder="$t('Key')"
                          :state="stateKey"
                          aria-describedby="input-live-help input-live-feedback"
                          v-maska="{ mask: 'rZZZZZZZZZ', tokens: { 'Z': { pattern: /[\w]/ }}}"
                          min="4"
                          @update="checkKeyRobot($event)"
            />
            <b-form-invalid-feedback v-show="publicRobot" id="input-live-feedback">
              {{ $t('key is busy')}}
            </b-form-invalid-feedback>

            <b-input-group class="mt-3"
                           v-show="publicRobot && stateKey"
            >
              <b-form-input v-model="axios.defaults.baseURL_app + '/a/' + keyRobot"
                            readonly
                            trim
              />
              <b-input-group-append v-show="stateKey">
                <div class="ml-2 icon"
                     @click="copyTextToClipboard(axios.defaults.baseURL_app + '/a/' + keyRobot)"
                >
                  <LinkCopy />
                </div>
              </b-input-group-append>
            </b-input-group>
          </b-form-group>
          <hr/>
          <b-form-group
            id="input-group-1"
            label-for="input-cent"
          >
            <b-form-checkbox
              v-model="realScope"
              @change="setRealScope($event)"
              id="input-cent"
              class="mb-2"
              switch
            >
              {{ realScope_label }}
            </b-form-checkbox>
          </b-form-group>
          <hr/>
          <b-form-group
            id="input-group-1"
            label-for="input-notification"
          >
            <b-form-checkbox
              v-model="notification"
              id="input-notification"
              class="mb-2"
              switch
            >
              {{ $t('Standard notifications') }}
            </b-form-checkbox>
          </b-form-group>
        </b-form>
      </div>
    </template>
    <template #footer>
      <div class="w-100">
        <b-button
          size="sm"
          class="float-right ml-2"
          variant="primary"
          id="save-button"
          @click="updateRobot_"
          :disabled="(title.trimStart() == '') || (stateKey == false)"
        >
          {{$t('Save')}}
        </b-button>

        <b-button
          size="sm"
          class="float-right"
          @click="showModal = false"
        >
          {{$t('Close')}}
        </b-button>

      </div>
    </template>
  </Modal>
</template>

<script>
import Modal from '../../ui/Modal'
import { mapActions, mapState } from 'vuex'
import LinkCopy from '@/assets/icons/copy.svg'

export default {
  name: 'SettingRobot',
  components: {
    Modal,
    LinkCopy,
  },
  props: [
    'item',
    'showModal',
  ],

  data () {
    return {
      title: this.item.title,
      publicRobot: false,
      keyRobot: 'r',
      stateKey: true,
      realScope: false,
      realScope_label: this.$t('Scope - cent'),
      notification: false,
    }
  },

  computed: {
    ...mapState('publicKey', [
      'publicKey',
    ]),
    ...mapState('robot', [
      'robots',
    ]),

    robotsOptions () {
      return this.robots.map((item) => {
        return ({
          title: item.title,
          id: item.id,
        })
      })
    },
  },
  methods: {
    ...mapActions('publicKey', [
      'getPublicKey',
      'checkPublicKey',
    ]),
    ...mapActions('robot', [
      'updateRobot',
    ]),

    async updateRobot_ () {
      await this.updateRobot([
        this.item.id,
        this.title,
        this.publicRobot,
        this.keyRobot,
        this.realScope,
        this.notification,
      ]).then((response) => {
        if (response) {
          this.$bvToast.toast(this.$t('Saved'), {
            title: this.$t('Notification'),
            autoHideDelay: 5000,
            variant: 'success',
            toaster: 'b-toaster-bottom-right',
          })
        } else {
          this.$bvToast.toast(this.$t('Error'), {
            title: this.$t('Notification'),
            autoHideDelay: 5000,
            variant: 'danger',
            toaster: 'b-toaster-bottom-right',
          })
        }
      })
    },

    async getKeyRobot (event) {
      if (event) {
        this.stateKey = false
        if (this.keyRobot == 'r') {
          this.stateKey = await this.getPublicKey('robot')
          this.keyRobot = this.publicKey
        } else {
          this.stateKey = true
        }
      } else {
        this.stateKey = true
      }
    },

    checkKeyRobot (ev) {
      if (ev.charAt(0) != 'r') {
        ev = 'r' + ev
      }
      this.stateKey = false
      if (new RegExp('^[gr][\\w]{3,9}$').test(ev)) {
        this.checkPublicKey(['robot', this.item.id, ev]).then(resp => {
          this.stateKey = resp
        })
      }
      this.keyRobot = ev
    },

    setRealScope (ev) {
      if (ev) {
        this.realScope_label = this.$t('Scope - real')
      } else {
        this.realScope_label = this.$t('Scope - cent')
      }
    },

    fallbackCopyTextToClipboard (text) {
      var textArea = document.createElement('textarea')
      textArea.value = text

      // Avoid scrolling to bottom
      textArea.style.top = '0'
      textArea.style.left = '0'
      textArea.style.position = 'fixed'

      document.body.appendChild(textArea)
      textArea.focus()
      textArea.select()

      try {
        document.execCommand('copy')

        this.$bvToast.toast(this.$t('Copied'), {
          title: this.$t('Notification'),
          autoHideDelay: 5000,
          variant: 'success',
          toaster: 'b-toaster-bottom-right',
        })
      } catch (err) {
        console.error('Fallback: Oops, unable to copy', err)
      }

      document.body.removeChild(textArea)
    },

    copyTextToClipboard (text) {
      if (!navigator.clipboard) {
        this.fallbackCopyTextToClipboard(text)
        return
      }
      navigator.clipboard.writeText(text).then(function () {
        this.$bvToast.toast(this.$t('Copied'), {
          title: this.$t('Notification'),
          autoHideDelay: 5000,
          variant: 'success',
          toaster: 'b-toaster-bottom-right',
        })
      }, function (err) {
        console.error('Async: Could not copy text: ', err)
      })
    },
  },

  created () {
    if (this.item.link) {
      this.publicRobot = this.item.link.isPublic == '1'
      if (this.item.link.url) {
        this.keyRobot = this.item.link.url
      }
    }
    if (this.item.hasOwnProperty('real')) {
      this.realScope = this.item.real == '1'
      this.setRealScope(this.realScope)
    }
    if (this.item.hasOwnProperty('robotNotification')) {
      this.notification = this.item.robotNotification == '1'
    }
  },

  i18n: {
    messages: {
      'en': {
        'Name bot': 'Name bot',
        'robots': 'Scopes',
        'Select scopes': 'Select scopes',
        'Public group': 'Public group',
        'Key': 'Key',
        'key is busy': 'Key is busy',
        'Setting bot': 'Setting bot',
        'Save': 'Save',
        'Close': 'Close',
        'Saved': 'Saved',
        'Deleted': 'Deleted',
        'Notification': 'Notification',
        'Error': 'Error',
      },
      'ru': {
        'Name bot': 'Имя бота',
        'scope selected': ' счет выбран',
        'robots': 'Счета',
        'Select scopes': 'Выберите счета',
        'Public group': 'Публичный',
        'Key': 'Ключ',
        'key is busy': 'Ключ уже занят',
        'Scope - cent': 'Счет - центовый',
        'Scope - real': 'Счет - реальный',
        'Standard notifications': 'Стандартные оповещения',
        'Setting bot': 'Настройки бота',
        'Save': 'Сохранить',
        'Close': 'Закрыть',
        'Saved': 'Сохранено',
        'Deleted': 'Удалено',
        'Notification': 'Уведомление',
        'Error': 'Ошибка',
      },
    },
  },
}
</script>

<style lang="scss" scoped>
.backdrop {
  background-color: #ffffff;
  opacity: 50%;
  width: 100%;
  height: 100%;
}

.icon {
  width: 22px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 10px;
  cursor: pointer;

  svg {
    width: 22px;
    height: 22px;
    fill: $grey;
    display: block;
    cursor: pointer;
  }
}
</style>
