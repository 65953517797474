import axios from 'axios'

const PUBLIC_GET = '/api/public/'
const PUBLIC_KEY_GET = '/api/links/get?type='
const PUBLIC_KEY_CHECK = '/api/links/'
const PUBLIC_KEY_CHECK_1 = '/'
const PUBLIC_KEY_CHECK_2 = '/check?link='

export const requestGet = (key, params) => {
  return axios.get(PUBLIC_GET + key, params)
}

export const requestGetPublicKey = (type, params) => {
  return axios.get(PUBLIC_KEY_GET + type, params)
}

export const requestCheckPublicKey = (type, id, key, params) => {
  return axios.get(PUBLIC_KEY_CHECK + type + PUBLIC_KEY_CHECK_1 + id + PUBLIC_KEY_CHECK_2 + key, params)
}
