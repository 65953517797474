// import { useKriptid } from '@/utils/helpers'
import { requestExpertTypes } from '@/requestManager/AdvisorsRequests'
// import Vue from 'vue'

const state = {
  expertTypes: [],
  isTabActive: true,
  timezone: null,
}

const getters = {
  hasCompletedTip: state => robots => {
    return ''
  },
}

const mutations = {
  SET_EXPERT_TYPES_DATA (state, expertTypes) {
    state.expertTypes = expertTypes
  },
}

const actions = {
  async getExpertTypes ({ state, commit, dispatch }) {
    const response = await requestExpertTypes()

    if ((response.data.success)) {
      const resp = await response.data.data.map(item => {
        if (item.expertChangelogs != []) {
          item.expertChangelogs.map(item1 => {
            if (item1.description != null) {
              item1.description = JSON.parse(item1.description)
            }
            return item1
          })
        }
        return item
      })

      commit('SET_EXPERT_TYPES_DATA', resp)
    }

    return response.data
  },
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
}
