<template>
  <div>
      <ul>
        <li>
          <div class="link" @click="$emit('showModalSetting')">
            <div class="link__icon">
              <IconSettings></IconSettings>
            </div>
            <div class="link__name">
              {{$t('Setting')}}
            </div>
          </div>

          <div class="link" @click="showModalDelete = true">
            <div class="link__icon">
              <IconDelete/>
            </div>
            <div class="link__wrapper">
              <div class="link__name">{{$t('Delete')}}</div>
            </div>
          </div>
        </li>
      </ul>

    <Modal :showModal="showModalDelete"
           @update:showModal="showModalDelete = $event"
           :label="$t('Do you really want to delete?')"
    >
      <template #footer>
        <b-button
          size="sm"
          class="float-right ml-2"
          variant="primary"
          id="save-button"
          @click="$emit('deleteRobot')"
        >
          {{$t('Yes')}}
        </b-button>

        <b-button
          size="sm"
          class="float-right"
          @click="showModalDelete = false"
        >
          {{$t('Close')}}
        </b-button>
      </template>
    </Modal>
  </div>
</template>

<script>
import SettingRobot from '../setting/SettingRobot'
import Modal from '../../ui/Modal'
import IconSettings from '@/assets/icons/settings2.svg'
import IconDelete from '@/assets/icons/delete.svg'

export default {
  name: 'ContextMenuCard',
  components: {
    SettingRobot,
    Modal,
    IconSettings,
    IconDelete,
  },
  props: [
    'showModalSetting',
    'deleteRobot',
  ],

  data () {
    return {
      showModalDelete: false,
    }
  },

  i18n: {
    messages: {
      'en': {
        'Setting': 'Setting',
        'Delete': 'Delete',
        'Do you really want to delete?': 'Do you really want to delete the bot?',
      },
      'ru': {
        'Setting': 'Настройки',
        'Delete': 'Удалить',
        'Do you really want to delete?': 'Вы действительно хотите удалить бота?',
      },
    },
  },
}
</script>

<style lang="scss" scoped>
.dropdown {
  position: absolute;
  border-radius: 4px;
  background: #fff;
  box-shadow: 0 0 13px 0 rgba(48, 48, 48, 0.27);
  width: 185px;
  top: 10px;
  right: 20px;
  margin-top: 5px;
  cursor: default;

  ul {
    padding: 10px 0;
    margin: 0;
    border-bottom: 1px solid #f0f0f0;

    li {
      list-style: none;

      .link {
        padding: 0 15px;
        font-size: 14px;
        display: flex;
        align-items: center;
        height: 33px;
        cursor: pointer;
        text-decoration: none;
        color: #2c2c2c;
        $root: &;

        &:hover {
          background: #f0f0f0;

          svg {
            path {
              &:nth-child(2) {
                fill: $blue
              }

              &:nth-child(1) {
                fill: $lightBlue;
              }
            }
          }
        }

        &__icon {
          width: 13px;
          display: flex;
          justify-content: center;
          align-items: center;
          margin-right: 10px;

          svg {
            width: 13px;
            height: 13px;
            fill: $grey;
            display: block;
          }
        }

        &__wrapper {
          display: flex;
          justify-content: space-between;
          align-items: center;
          flex: 1 1 auto;
        }
      }
    }
  }
}
</style>
