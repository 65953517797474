<template>
  <Modal
          :label="label"
          :showModal="showModal"
          @update:showModal="$emit('update:showModal', $event)"
  >
    <template #content>
      <b-form>
        <b-form-group
          id="input-group-1"
          :label="$t('Name group')"
          label-for="input-name"
        >
          <b-form-input :value="titleGroup"
                        id="input-name"
                        @input="$emit('update:titleGroup', $event)"
          />
        </b-form-group>

        <b-form-group
          id="input-group-2"
          :label="$t('robots')"
          label-for="input-robots"
        >
          <Multiselect id="input-robots"
                       :value="selected"
                       @input="$emit('update:selected', $event)"
                       :options="robotsOptions"
                       :multiple="true"
                       :close-on-select="false"
                       :clear-on-select="false"
                       :preserve-search="true"
                       :placeholder="$t('Select scopes')"
                       label="title"
                       track-by="title"
                       :v-model="selected"
          />
        </b-form-group>
        <b-form-group
          id="input-group-3"
          label-for="input-keyGroup"
        >
          <b-form-checkbox
            id="input-keyGroup"
            v-model="publicGroup"
            class="mb-2"
            switch
            @change="getKeyGroup($event)"
          >
            {{ $t('Public group')}}
          </b-form-checkbox>
          <b-form-input v-show="publicGroup"
                        v-model="keyGroup"
                        trim
                        :placeholder="$t('Key')"
                        :state="stateKey"
                        aria-describedby="input-live-help input-live-feedback"
                        v-maska="{ mask: 'gZZZZZZZZZ', tokens: { 'Z': { pattern: /[\w]/ }}}"
                        min="4"
                        @input="checkKeyGroup($event)"
          />
          <b-form-invalid-feedback v-show="publicGroup" id="input-live-feedback">
            {{ $t('key is busy')}}
          </b-form-invalid-feedback>

          <b-input-group class="mt-3"
                         v-show="publicGroup && stateKey"
          >
            <b-form-input v-model="axios.defaults.baseURL_app + '/a/' + keyGroup"
                          readonly
                          trim
            />
            <b-input-group-append v-show="stateKey">
              <div class="ml-2 icon"
                   @click="copyTextToClipboard(axios.defaults.baseURL_app + '/a/' + keyGroup)"
              >
                <LinkCopy />
              </div>
            </b-input-group-append>
          </b-input-group>

        </b-form-group>
      </b-form>
    </template>

    <template #footer>
      <div class="w-100">
        <b-button
          size="sm"
          class="float-right ml-2"
          variant="primary"
          id="save-button"
          @click="$emit('ok', true)"
          :disabled="(titleGroup.trimStart() == '') || (stateKey == false)"
        >
          {{ labelOK }}
        </b-button>

        <b-button
          size="sm"
          class="float-right"
          @click="$emit('update:showModal', false)"
        >
          {{$t('Close')}}
        </b-button>

      </div>
    </template>
  </Modal>
</template>

<script>
import Modal from '../../ui/Modal'
import Multiselect from 'vue-multiselect'
import { mapActions, mapState } from 'vuex'
import LinkCopy from '@/assets/icons/copy.svg'

export default {
  name: 'SettingGroup',
  components: {
    Modal,
    Multiselect,
    LinkCopy,
  },

  props: {
    'id': {
      default: '',
    },
    'label': {},
    'showModal': {},
    'titleGroup': {},
    'selected': {},
    'labelOK': {},
    'publicGroup': {},
    'keyGroup': {},
  },

  data () {
    return {
      stateKey: true,
    }
  },

  computed: {
    ...mapState('publicKey', [
      'publicKey',
    ]),
    ...mapState('robot', [
      'robots',
    ]),

    robotsOptions () {
      return this.robots.map((item) => {
        return ({
          title: item.title,
          id: item.id,
        })
      })
    },
  },

  methods: {
    ...mapActions('publicKey', [
      'getPublicKey',
      'checkPublicKey',
    ]),
    ...mapActions('robot', [
      'getRobots',
    ]),

    async getKeyGroup (event) {
      this.$emit('update:publicGroup', event)
      if (event) {
        this.stateKey = false
        if (this.keyGroup == '') {
          this.stateKey = await this.getPublicKey('group')
          this.keyGroup = this.publicKey
        } else {
          this.stateKey = true
        }
        this.$emit('update:keyGroup', this.keyGroup)
      } else {
        this.stateKey = true
      }
    },

    checkKeyGroup (ev) {
      if (ev.charAt(0) != 'g') {
        ev = 'g' + ev
      }
      this.stateKey = false
      if (new RegExp('^[gr][\\w]{3,9}$').test(ev)) {
        this.checkPublicKey(['group', this.id, ev]).then(resp => {
          this.stateKey = resp
        })
      }
      this.keyGroup = ev
      this.$emit('update:keyGroup', this.keyGroup)
    },

    fallbackCopyTextToClipboard (text) {
      var textArea = document.createElement('textarea')
      textArea.value = text

      // Avoid scrolling to bottom
      textArea.style.top = '0'
      textArea.style.left = '0'
      textArea.style.position = 'fixed'

      document.body.appendChild(textArea)
      textArea.focus()
      textArea.select()

      try {
        document.execCommand('copy')

        this.$bvToast.toast(this.$t('Copied'), {
          title: this.$t('Notification'),
          autoHideDelay: 5000,
          variant: 'success',
          toaster: 'b-toaster-bottom-right',
        })
      } catch (err) {
        console.error('Fallback: Oops, unable to copy', err)
      }

      document.body.removeChild(textArea)
    },

    copyTextToClipboard (text) {
      if (!navigator.clipboard) {
        this.fallbackCopyTextToClipboard(text)
        return
      }
      navigator.clipboard.writeText(text).then(function () {
        this.$bvToast.toast(this.$t('Copied'), {
          title: this.$t('Notification'),
          autoHideDelay: 5000,
          variant: 'success',
          toaster: 'b-toaster-bottom-right',
        })
      }, function (err) {
        console.error('Async: Could not copy text: ', err)
      })
    },
  },

  created: async function () {
    await this.getRobots()
  },

  i18n: {
    messages: {
      'en': {
        'Name group': 'Name group',
        'robots': 'Scopes',
        'Select scopes': 'Select scopes',
        'Public group': 'Public group',
        'Key': 'Key',
        'key is busy': 'Key is busy (must have at least 4 characters)',
        'Close': 'Close',
      },
      'ru': {
        'Name group': 'Имя группы',
        'scope selected': ' счет выбран',
        'robots': 'Счета',
        'Select scopes': 'Выберите счета',
        'Public group': 'Публичный',
        'Key': 'Ключ',
        'key is busy': 'Ключ уже занят (должен иметь хотя бы 4 символа)',
        'Close': 'Закрыть',
      },
    },
  },
}
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>

<style scoped>
.icon {
  width: 22px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 10px;
  cursor: pointer;

  svg {
    width: 22px;
    height: 22px;
    fill: $grey;
    display: block;
    cursor: pointer;
  }
}

</style>
