import axios from 'axios'

const NOTIFICATION_GET_ALL = '/api/trader-notification-settings'
const NOTIFICATION_CREATE = '/api/trader-notification-settings/create'
const NOTIFICATION_DELETE = '/api/trader-notification-settings/'
const NOTIFICATION_DELETE_1 = '/delete'
const NOTIFICATION_UPDATE = '/api/trader-notification-settings/'
const NOTIFICATION_UPDATE_1 = '/update'
const NOTIFICATION_SELECT_VALUES = '/api/trader-notification-settings/select-values'

export const requestGetNotifications = params => {
  return axios.get(NOTIFICATION_GET_ALL, params)
}

export const requestCreateNotify = params => {
  return axios.post(NOTIFICATION_CREATE, params)
}

export const requestDeleteNotify = (index, params) => {
  return axios.delete(NOTIFICATION_DELETE + index + NOTIFICATION_DELETE_1, params)
}

export const requestUpdateNotify = (index, params) => {
  return axios.put(NOTIFICATION_UPDATE + index + NOTIFICATION_UPDATE_1, params)
}

export const requestSelectValues = params => {
  return axios.get(NOTIFICATION_SELECT_VALUES, params)
}
