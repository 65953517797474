export const languages = [
  {
    name: 'ru',
    component: 'IconRu',
    code: 'ru',
  },
  {
    name: 'en',
    component: 'IconEn',
    code: 'en',
  },
]
