// import { useKriptid } from '@/utils/helpers'
import axios from 'axios'

let userIdIsSent = false

const state = {
  user: null,
  isTabActive: true,
  timezone: null,
}

const getters = {
  hasCompletedTip: state => name => {
    return state.user.tips.find(element => element == name)
  },
}

const mutations = {
  ADD_COMPLETED_TIP (state, name) {
    if (!state.user.tips.find(tip => tip == name)) {
      state.user.tips.push(name)
    }
  },

  SET_USER_PROPERTY (state, data) {
    state.user[data.property] = data.value
  },
  SET_USER_DATA (state, user) {
    state.user = user
  },
  SET_USER_LANGUAGE (state, language) {
    state.user.language = language
  },

  SWITCH_NOTIFICATION_MODE (state, data) {
    const item = state.user.notification.groups.find(group => {
      return group.notifications.find(el => el.notification == data.notification)
    }).notifications.find(el => el.notification == data.notification)

    item.switchers[data.type] = data.enabled
  },

  SET_USING_TYPE (state, type) {
    state.user.usingType = type
  },

  TOGGLE_INSTANT (state) {
    state.user.notification.instant = !state.user.notification.instant
  },
  TOGGLE_SOUND (state) {
    state.user.notification.playSound = !state.user.notification.playSound
  },
  TOGGLE_WEBPUSH (state) {
    state.user.notification.webPush = !state.user.notification.webPush
  },

  CHANGE_SOCIAL_BIND (state, data) {
    state.user.socials[data.provider] = data.status
  },
  CHANGE_THEME (state, theme) {
    state.user.theme = theme
  },
  CHANGE_POMODORO (state, data) {
    state.user.pomodoro = data
  },

  UPDATE_CALENDAR_SETTINGS (state, data) {
    state.user.calendarSettings = {
      ...state.user.calendarSettings,
      ...data,
    }
  },

  SET_IS_TAB_ACTIVE (state, isActive) {
    state.isTabActive = isActive
  },
}

const actions = {
  addCompletedTip ({ commit }, name) {
    axios.post('user/tip/add', { name })
    commit('ADD_COMPLETED_TIP', name)
  },

  async changeTheme ({ commit }, theme) {
    const response = await axios.post('user/update/theme', { theme })
    commit('CHANGE_THEME', theme)

    return response
  },

  async toggleSound ({ commit }) {
    const response = await axios.post('user/notification/toggle-play-notification-sound')
    commit('TOGGLE_SOUND')

    return response
  },

  async toggleWebpush ({ commit }) {
    const response = await axios.post('user/notification/toggle-webpush-notification')
    commit('TOGGLE_WEBPUSH')

    return response
  },

  async toggleInstant ({ commit }) {
    const response = await axios.post('user/notification/toggle-instant-notification')
    commit('TOGGLE_INSTANT')

    return response
  },

  async setUsingType ({ commit }, object) {
    const response = await axios.post('user/update/using-type', object)
    commit('SET_USING_TYPE', object.type)

    return response
  },

  async setUserLanguage ({ commit, dispatch }, language) {
    const response = await axios.post('api/user/update/language', { languageCode: language })
    const { success } = response.data

    if (success) {
      commit('SET_USER_LANGUAGE', language)
      dispatch('general/setLanguage', language, { root: true })
    }
  },

  async getUserData ({ state, commit, dispatch }) {
    const response = await axios.get('api/user/me').then(response => {
      const { data, success } = response.data
      if (success) {
        commit('SET_USER_DATA', data)
        commit('SET_USER_LANGUAGE', data.language)
        dispatch('general/setLanguage', data.language, { root: true })

        if (userIdIsSent) {
          return
        }

        // useKriptid(2, 'set', 'userId', state.user.uid)
        userIdIsSent = true
      }

      if (!localStorage.getItem('language')) {
        dispatch('setUserLanguage', data.language)
      }
    })

    return response
  },

  async updateUserData (context, data) {
    const sendingData = {
      ...data,
      logo: data.logoFile,
      language: state.user.language,
    }

    return await axios.post('user/update/profile', sendingData)
  },

  async updateUserLogo (context, data) {
    const sendingLogo = {
      ...data,
      logo: null,
      language: state.user.language,
    }

    return await axios.post('user/update/profile', sendingLogo)
  },

  async updateUserAppearance (context, data) {
    return await axios.post('/user/update/appearance', data)
  },
  async changePassword (context, data) {
    return await axios.post('user/update/password', data)
  },

  async verifyEmail () {
    return await axios.post('user/verification/get-link')
  },

  // TODO: убрать избыточный экшен
  switchNotificationMode ({ commit }, data) {
    commit('SWITCH_NOTIFICATION_MODE', data)
  },

  // TODO: убрать избыточный экшен
  changeSocialBind ({ commit }, data) {
    commit('CHANGE_SOCIAL_BIND', data)
  },

  changePomodoro ({ commit }, data) {
    axios.post('user/update/pomodoro', data)
    commit('CHANGE_POMODORO', data)
  },

  async updateCalendarSettings ({ commit }, data) {
    await axios.post('user/update/calendar-settings', {
      ...state.user.calendarSettings,
      ...data,
    })

    commit('UPDATE_CALENDAR_SETTINGS', data)
  },

  // TODO: убрать избыточный экшен
  setIsTabActive ({ commit }, isActive) {
    commit('SET_IS_TAB_ACTIVE', isActive)
  },
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
}
