export const errorsLog = {
  data: () => ({
    errors: [],
    generalError: {
      status: false,
      text: null,
    },
    errorShowed: false,
    generalErrorShowed: false,
  }),
  computed: {
    rulesForGeneralError () {
      return this.generalError.status && !this.statusAllFields
        ? this.generalError.status
        : null
    },
    booleanRulesForGeneralError () {
      return !this.statusAllFields && this.generalError.status
    },
    errorsShowed () {
      return this.errorShowed || this.generalErrorShowed
    },
  },

  methods: {
    onInputErrors (error) {
      if (error.option === 'add') {
        this.errors.push(error)
      } else if (error.option === 'delete') {
        this.errors = this.errors.filter(errorArr => errorArr.text != error.text)
      }
    },
    onFocusInputWithError (errorText) {
      this.errors.find(error => error.text === errorText).isVisible = false
    },
    onBlurInputWithError (errorText) {
      this.errors.find(error => error.text === errorText).isVisible = true
    },
    setGeneralError (status, text) {
      this.generalError = {
        status,
        text,
      }
    },
    clearErrors () {
      this.errors = []
      this.generalError = {
        status: false,
        text: null,
      }
    },
    erorrEnter ($event) {
      this.errorShowed = $event
    },
    errorAfterLeave ($event) {
      if (!this.errors.length) {
        this.errorShowed = $event
      }
    },
    generalErrorEnter ($event) {
      this.generalErrorShowed = $event
    },
    generalErrorAfterLeave ($event) {
      this.generalErrorShowed = $event
    },
  },
}
