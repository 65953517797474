<template>
  <div class="home">
    <div class="mt-5 mb-3 row justify-content-between px-3 mx-2 align-items-end">
      <h2 class="align-middle">
        {{ $t('My Groups') }}
      </h2>
        <b-button variant="primary"
                  v-on:click="showModal = true"
      >
        {{ $t('Add group')}}
      </b-button>
    </div>

    <div class="mt-3 pr-2 d-flex flex-column flex-wrap justify-content-center m-0 align-content-center ml-md-3 p-0 pb-2">
      <template
        v-for="group in groups">
        <div class=" col-lg-8 col-12">
        <Group class="mx-1 col-12 col-md-5_"
               :item="group"
        />
        </div>
      </template>
    </div>

    <SettingGroup
      :showModal.sync="showModal"
      :label="$t('Create group')"
      :labelOK="$t('Create')"
      @ok="createGroup([titleGroup, selectedRobots, publicGroup, keyGroup])"
      :titleGroup.sync="titleGroup"
      :selected.sync="selected"
      :publicGroup.sync="publicGroup"
      :keyGroup.sync="keyGroup"
    />
  </div>
</template>

<script>
import Group from '@/components/groups/Group'
import SettingGroup from '@/components/groups/setting/SettingGroup'
import { mapState, mapActions } from 'vuex'

export default {
  name: 'Groups',
  components: {
    Group,
    SettingGroup,
  },
  data () {
    return {
      showModal: false,
      titleGroup: '',
      selected: '',
      publicGroup: false,
      keyGroup: '',
    }
  },

  computed: {
    ...mapState('groups', [
      'groups',
    ]),
    ...mapState('robot', [
      'robots',
    ]),

    selectedRobots () {
      return this.selected.map((item) => {
        return (item.id)
      })
    },
  },

  methods: {
    ...mapActions('groups', [
      'getGroups',
      'createGroup',
    ]),
    ...mapActions('robot', [
      'getRobots',
    ]),
    ...mapActions('general', [
      'setLoading',
    ]),
  },

  created: async function () {
    await this.getGroups()

    this.intervalGetGroups = setInterval(async () => {
      await this.getGroups()
    }, 10000)
    this.setLoading(false)
  },

  beforeDestroy () {
    if (this.intervalGetGroups) clearInterval(this.intervalGetGroups)
  },

  i18n: {
    messages: {
      'en': {
        'My Groups': 'My Groups',
        'Add group': 'Add group',
        'Create group': 'Create group',
        'Create': 'Create',
      },
      'ru': {
        'My Groups': 'Мои группы',
        'Add group': 'Добавить группу',
        'Create group': 'Создать группу',
        'Create': 'Создать',
      },
    },
  },
}
</script>

<style lang="scss">
.home {
  overflow: auto;
  overflow-x:hidden;
  padding-right: 25px;
  padding-left: 15px;
}

@media (min-width: 1100px) {
  .col-md-5_ {
    flex: 0 0 48%;
    max-width: 48%;
  }
}
</style>
