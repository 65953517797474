<template>
  <div class="container jumbotron mt-0 pb-2 pt-4 shadow-sm"
       :class="{'tradingInfo_null': !traidingInfoExist}"
  >
    <div class="d-flex flex-sm-row flex-lg-row justify-content-lg-between justify-content-end mt-1 mb-1"
    >
      <router-link class="col-7 text-4 font-weight-bold cursor-pointer title__robot"
          :class="{'col-11': !traidingInfoExist, 'col-7': traidingInfoExist}"
          :to="'/robots/' + item.id"
      >
        {{item.title}}
      </router-link>
      <p class="text-3"> {{item.lastUpdate}} </p>

      <ContextMenu :id="'contextMenu' + item.id"
                   class="pl-1 pl-md-2 pl-lg-0"
                   v-show="!not_setting"
      >
        <template  #context-menu-content>
          <ContextMenuCard @showModalSetting="showModal = true"
                           @deleteRobot="deletingRobot()"
          />
        </template>
      </ContextMenu>
    </div>

    <slot name="content"
          v-if="traidingInfoExist"
    />

    <div class="d-flex flex-column align-content-center justify-content-center"
         v-else
    >
      {{ $t('No data from the terminal.' )}}
      <p>
        {{ $t('Check the functionality of the terminal and the correct installation of the Expert Advisor') }}
      </p>
    </div>

    <div class="d-flex justify-content-end mt-1 py-0"
         v-if="traidingInfoExist"
    >
      <p class="text-2">
        {{lastTime}}
      </p>
    </div>

    <SettingRobot :showModal="showModal"
                  @update:showModal="showModal = $event"
                  :item="item"
    />
  </div>

</template>

<script>
import ContextMenuCard from './menu/ContextMenuCard'
import ContextMenu from '../ui/ContextMenu'
import SettingRobot from './setting/SettingRobot'
import { mapActions } from 'vuex'

export default {
  name: 'Card',
  components: {
    SettingRobot,
    ContextMenu,
    ContextMenuCard,
  },
  props: {
    item: Object,
    lastTime: String,
    not_setting: Boolean,
  },
  data () {
    return {
      showModal: false,
      stateKey: true,
      showPopoverSetting: false,
      showPopoverDelete: false,
      targetPopoverDelete: '',
    }
  },

  computed: {
    traidingInfoExist () {
      return (this.item.tradingInfo != null)
    },
  },

  methods: {
    ...mapActions('robot', [
      'changeNameRobot',
      'deleteRobot',
    ]),

    async deletingRobot () {
      await this.deleteRobot(this.item.id).then((response) => {
        if (response) {
          this.$bvToast.toast(this.$t('Deleted'), {
            title: this.$t('Notification'),
            autoHideDelay: 5000,
            variant: 'success',
            toaster: 'b-toaster-bottom-right',
          })
        } else {
          this.$bvToast.toast(this.$t('Error'), {
            title: this.$t('Notification'),
            autoHideDelay: 5000,
            variant: 'danger',
            toaster: 'b-toaster-bottom-right',
          })
        }
      })
    },
  },

  i18n: {
    messages: {
      'ru': {
        'No data from the terminal.': 'Нет данных с терминала.',
        'Check the functionality of the terminal and the correct installation of the Expert Advisor': 'Проверьте работоспособность терминала и правильную установку советника',
      },
    },
  },
}
</script>

<style lang="scss" scoped>
.card {
  height: 100%;
}
.cursor-pointer {
  cursor: pointer;
}

.title__robot{
  color: #2c3e50;
  &:hover {
    text-decoration: underline;
  }
}

.tradingInfo_null {
  display: grid;
  grid-template-rows: auto 1fr;
  background-color: #DD6565;
  width: 100%;
  height: 100%;
  padding: 20px;

  &_small {
    background-color: #DD6565;
    width: 35%;
    height: 100%;
    margin-right: 20px;
  }
}
</style>
