<template>
  <div :class="['errors-log', {'errors-log_showed': showErrors }]">
    <transition-group
        name="error"
        @enter="errorEnter"
        @after-leave="errorAfterLeave"
    >
      <!-- eslint-disable -->
      <VTextError
          v-for="error in uniqueErrors"
          :key="error.text"
          v-if="error.isVisible"
          :class="[
          'errors-log__error',
          { 'errors-log__error_visible': error.isVisible },
        ]"
      >
        {{ $t(error.text) }}
      </VTextError>
      <!-- eslint-enable -->
    </transition-group>

    <transition
        name="error"
        @enter="generalErrorEnter"
        @after-leave="generalErrorAfterLeave"
    >
      <VTextError v-if="booleanRulesForGeneralError">
        {{ $t(generalError.text) }}
      </VTextError>
    </transition>
  </div>
</template>

<script>
import VTextError from '@/components/ui/Auth/VTextError'

export default {
  name: 'ErrorsLog',
  components: {
    VTextError,
  },

  props: {
    errorsShowed: {
      type: Boolean,
      required: true,
    },
    errors: {
      type: Array,
      required: true,
    },
    generalError: {
      type: Object,
      default: () => ({
        status: false,
        text: null,
      }),
    },
    booleanRulesForGeneralError: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    uniqueErrors () {
      return Array.from(new Set(this.errors))
    },
    showErrors () {
      return (this.errorsShowed && this.errors.some(error => error.isVisible)) ||
          this.booleanRulesForGeneralError
    },
  },

  methods: {
    errorEnter () {
      this.$emit('error-enter', true)
    },
    errorAfterLeave () {
      this.$emit('error-after-leave', false)
    },
    generalErrorEnter () {
      this.$emit('general-error-enter', true)
    },
    generalErrorAfterLeave () {
      this.$emit('general-error-after-leave', false)
    },
  },

  i18n: {
    messages: {
      'en': {
        'Email address entered incorrectly': 'Email address entered incorrectly',
        'You need to enter your email address': 'You need to enter your email address',
        'Password entered incorrectly': 'Password entered incorrectly',
        'All highlighted fields must be filled in': 'All highlighted fields must be filled in',
        'Invalid email or password': 'Invalid email or password',
        'Connection cannot be established': 'Connection cannot be established',
        'This email address is already registered': 'This email address is already registered',
        'Try again': 'Try again',
        'The minimum password length is 8 characters': 'The minimum password length is 8 characters',
        'This email address is not registered': 'This email address is not registered',
      },
      'ru': {
        'Email address entered incorrectly': 'Email введён некорректно',
        'You need to enter your email address': 'Необходимо ввести email',
        'Password entered incorrectly': 'Пароль введён некорректно',
        'All highlighted fields must be filled in': 'Необходимо заполнить все подсвеченные поля',
        'Invalid email or password': 'Неверный email или пароль',
        'Connection cannot be established': 'Не установлено соединение с интернетом',
        'This email address is already registered': 'Указанный адрес электронной почты уже используется',
        'Try again': 'Попробуйте снова',
        'The minimum password length is 8 characters': 'Пароль должен содержать минимум 8 символов',
        'This email address is not registered': 'Пользователя с указанной электронной почтой не существует',
      },
    },
  },
}
</script>

<style lang="scss" scoped>
.errors-log {
  display: grid;
  grid-row-gap: 4px;
  margin-bottom: 0;
  transition: .55s ease-in-out;
}

.errors-log_showed {
  margin-bottom: 20px;
  transition: .4s ease;
}

.errors-log__error {
  pointer-events: none;
  height: 0;
  overflow: hidden;
}

.errors-log__error_visible {
  pointer-events: auto;
  height: max-content;
}

.error-enter-active,
.error-leave-active {
  transition: all .4s ease;
}

.error-enter {
  opacity: 0;
  transform: translateY(5px);
}

.error-leave-to {
  opacity: 0;
  transform: translateX(-10px);
}
</style>
