<template>
  <div class="home">
    <b-form-select v-model="mode"
                   :options="items"
                   class="mb-4"
                   @input="$router.push({ path: '/robots', query: { 'mode': mode }})"
    />
    <div class="d-flex flex-column flex-md-row flex-wrap justify-content-between m-0 ml-md-3 p-0">
      <template v-if="mode == 'advanced'">
        <Card class="mx-1 col-12 col-md-5_"
                      v-for="robot in robots"
                      :item="robot"
                      :lastTime="lastTime"
        >
          <template #content>
            <CardAdvanced :item="robot"/>
          </template>
        </Card>
      </template>
      <template v-else-if="mode == 'basic'">
        <Card class="mx-1 col-12 col-md-5_"
                   v-for="robot in robots"
                   :item="robot"
                   :lastTime="lastTime"
        >
          <template #content>
            <CardLite :item="robot"/>
          </template>
        </Card>
      </template>
    </div>
    <SummaryRobot v-show="mode == 'summary'"
                  :robots="robots"
                  :time="time"
                  :lastTime="lastTime"
    />
  </div>
</template>

<script>
import VSelect from '../../components/ui/VSelect'
import Card from '../../components/robot/Card'
import CardAdvanced from '../../components/robot/CardAdvanced'
import CardLite from '../../components/robot/CardLite'
import SummaryRobot from '../../components/robot/SummaryRobot'
import { mapState, mapActions } from 'vuex'

export default {
  name: 'Index',
  components: {
    Card,
    CardAdvanced,
    CardLite,
    SummaryRobot,
    VSelect,
  },
  data () {
    return {
      cards: [
      ],
      time: new Date().getTime(),
      mode: 'advanced',
      lastTime: '',
    }
  },

  computed: {
    ...mapState('robot', [
      'robots',
    ]),

    items () {
      return [
        {
          value: 'advanced',
          text: this.$t('Advanced mode'),
        },
        {
          value: 'basic',
          text: this.$t('Basic mode'),
        },
        {
          value: 'summary',
          text: this.$t('Summary mode'),
        },
      ]
    },
  },

  methods: {
    ...mapActions('robot', [
      'getRobots',
    ]),
    ...mapActions('general', [
      'setLoading',
    ]),

    setLastTime () {
      const time_ = new Date(new Date().getTime() - this.time)
      if (time_.getTime() / 1000 == 0) {
        this.lastTime = this.$t('Last Update: now')
      }
      else if (time_.getTime() / 1000 >= 20) {
        this.lastTime = this.$t('Last Update') + ': ' + new Date(this.time).toLocaleString()
      } else {
        this.lastTime = this.$t('Last Update') + ': ' + Math.round(parseInt(time_.getTime()) / 1000) + ' ' + this.$t('second age')
      }
    },
  },

  watch: {
    mode (value) {
      setTimeout(() => {
        this.setLoading(false)
      }, 200)
    },
  },

  created: async function () {
    if (this.$route.query.mode) {
      this.mode = this.$route.query.mode
    }

    this.getRobots()
    this.time = new Date().getTime()

    this.intervalGetRobots = setInterval(async () => {
      await this.getRobots()
      this.time = new Date().getTime()
    }, 10000)

    this.setLastTime()
    this.intervalLastTime = setInterval(() => {
      this.setLastTime()
    }, 4000)
    this.setLoading(false)
  },

  beforeDestroy () {
    if (this.intervalGetRobots) clearInterval(this.intervalGetRobots)
    if (this.intervalLastTime) clearInterval(this.intervalLastTime)
  },
}
</script>

<style lang="scss">
.home {
  overflow: auto;
  overflow-x:hidden
}
.robots {
  display: flex;
}

@media (min-width: 1200px) {
  .col-md-5_ {
    flex: 0 0 48%;
    max-width: 48%;
  }
}
</style>
