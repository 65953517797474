import axios from 'axios'

const ROBOTS_ALL = '/api/private-robots/index'
const ROBOTS_ALL_BY_GROUP = '/api/private-robots/by-group/'
const ROBOT_DELETE = '/api/private-robots/delete/'
const ROBOT_UPDATE = '/api/private-robots/update/'
const ROBOT_ADVANCED = '/api/private-robots/advanced/'
const ROBOT_ORDERS = '/api/private-robots/orders-info/'
const ROBOT_ORDERS_1 = '?page='

export const requestRobots = params => {
  return axios.get(ROBOTS_ALL, params)
}

export const requestRobotsByGroup = (index, params) => {
  return axios.get(ROBOTS_ALL_BY_GROUP + index, params)
}

export const requestDeleteRobot = params => {
  return axios.delete(ROBOT_DELETE + params)
}

export const requestUpdateRobot = (index, params) => {
  return axios.put(ROBOT_UPDATE + index, params)
}

export const requestAdvancedRobot = (index, params) => {
  return axios.get(ROBOT_ADVANCED + index, params)
}

export const requestOrdersRobot = (index, page, params) => {
  return axios.get(ROBOT_ORDERS + index + ROBOT_ORDERS_1 + page, params)
}
