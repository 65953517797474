<script>
import { Line, mixins } from 'vue-chartjs'
const { reactiveProp } = mixins

export default {
  name: 'LineChart',
  extends: Line,
  mixins: [
    reactiveProp,
  ],
  props: {
    chartData: {
      type: Object,
      default: () => {
        return {
          datasets: [],
        }
      },
    },
    options: {
      type: Object,
      default: null,
    },
    chartResize: {
      type: Boolean,
      default: false,
    },
  },

  watch: {
    chartResize (newVal, oldVal) {
      if (this.chartResize) {
        this.renderChart(this.chartData, this.options)
        this.$emit('update:chartResize', false)
      }
    },
  },

  mounted () {
    this.renderChart(this.chartData, this.options)
  },
}
</script>

<style scoped>

</style>
