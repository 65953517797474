/* eslint-disable */

export const validate = {
  email: {
    reg: /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
    textError: 'Email address entered incorrectly',
  },
  password: {
    reg: /.+/,
    textError: null,
  },
  newPassword: {
    reg: /.{8,}/,
    textError: 'The minimum password length is 8 characters',
  },
  firstName: {
    reg: /.+/,
    textError: null,
  },
  username: {
    reg: /.+/,
    textError: null,
  },
  lastName: {
    reg: /.+/,
    textError: null,
  },
  generalErrors: {
    allHighlightedFieldsMustBeFilled: 'All highlighted fields must be filled in',
    invalidEmailOrPassword: 'Invalid email or password',
    connectionFalse: 'Connection cannot be established',
    tryAgain: 'Try again',
    emailAlreadyRegistered: 'This email address is already registered',
    nonexistentEmail: 'This email address is not registered',
  },
}
