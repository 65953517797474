export const authForms = {
  data: () => ({
    isLoading: {
      status: false,
      text: 'Logging in...',
    },
    inputDisabled: false,
  }),

  methods: {
    changeForm ($event, field) {
      this.form[field] = $event
    },
    setFormLoadingState (bool) {
      this.isLoading.status = bool
      this.inputDisabled = bool
    },
  },
}
