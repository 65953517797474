<template>
  <b-button variant="primary"
    type="submit"
  >
    <div
      v-if="!isLoading.status"
      class="v-button__container"
    >
      <slot />
    </div>

    <div
      v-else
      class="v-button__loading"
    >
      <IconButtonLoader class="v-button__loading-loader" />
      <div>
        {{ $t(isLoading.text) }}
      </div>
    </div>
  </b-button>
</template>

<script>
import IconButtonLoader from '@/assets/icons/button-loader.svg'

export default {
  name: 'VButton',
  components: {
    IconButtonLoader,
  },

  props: {
    isLoading: {
      type: Object,
      default: () => {
        return {
          status: false,
          text: 'Loading',
        }
      },
    },
  },

  i18n: {
    messages: {
      'en': {
        'Logging in...': 'Logging in...',
      },
      'ru': {
        'Logging in...': 'Загрузка',
      },
    },
  },
}
</script>

<style lang="scss" scoped>
.v-button {
  padding: 11px 22px;
  width: 100%;
  font-size: 16px;
  font-variation-settings: 'wght' 600;
  line-height: 22px;
  color: #FFF;
  background-color: #6788F3;
  border-radius: 8px;
  border: none;
  outline: none;
}

.v-button__container {
  display: flex;
  justify-content: center;
}

.v-button__loading {
  display: grid;
  grid-template-columns: repeat(2, max-content);
  justify-content: center;
  grid-column-gap: 8px;
}

.v-button__loading-loader {
  height: 20px;
  width: 20px;
  animation: rotating 1.5s infinite linear;
}

@keyframes rotating {
  to {
    transform: rotate(360deg);
  }
}
</style>
