import { useKriptid } from '@/utils/helpers'
import axios from 'axios'
import { tariffPeriods } from '@/utils/constants/models/tariff'

const state = {
  tariffPeriods,
  tariffPage: null,
  tariffId: null,
  tariff: null,
  period: tariffPeriods.YEARLY,
  // TODO: отрефакторить на `people`
  peoples: 6,
  plans: [],
  cards: [],
  transactions: [],
}

const getters = {
  selectedPlan: state => {
    return state.plans.find(plan => plan.id === state.tariffId)
  },
}

const mutations = {
  SET_TARIFF_PAGE (state, value) {
    state.tariffPage = value
  },
  SET_TARIFF_ID (state, value) {
    state.tariffId = value
  },
  SET_TARIFF (state, tariff) {
    state.tariff = tariff
  },
  SET_PERIOD (state, value) {
    state.period = value
  },
  SET_PEOPLES (state, value) {
    state.peoples = value
  },
  SET_PLANS (state, plans) {
    state.plans = plans
  },
  SET_CARDS (state, cards) {
    state.cards = cards
  },
  SET_TRANSACTIONS (state, transactions) {
    state.transactions = transactions
  },

  REMOVE_CARD (state, id) {
    state.cards = state.cards.filter(card => card.id !== id)
  },
  RESET (state) {
    state.tariffPage = null
    state.tariffId = null
    state.tariff = null
    state.period = state.tariffPeriods.YEARLY
    state.peoples = 5
    state.plans = []
    state.cards = []
    state.transactions = []
  },
}

const actions = {
  // TODO: убрать избыточный экшен
  reset ({ commit }) {
    commit('RESET')
  },
  async removeCard ({ commit }, id) {
    const response = await axios.post('billing/payment/remove-card', { id })

    if (response.data.success) {
      commit('REMOVE_CARD', id)
    }
  },
  async changePeriod (context, { period, workspaceId }) {
    return await axios.post('billing/payment/change-period', {
      period,
      workspace_id: workspaceId,
    })
  },
  // TODO: отрефакторить `peoples` в `people`
  async changeUserNumber (context, { peoples, workspaceId }) {
    const response = await axios.post('billing/payment/change-users-number', {
      peoples,
      workspace_id: workspaceId,
    })

    if (response.data.success && response.data.redirectUrl) {
      window.open(response.data.redirectUrl)
    }

    return response
  },
  async getPaymentInfo ({ commit }, workspaceId) {
    const response = await axios.get('billing/payment/get-payment-info', {
      params: {
        workspace_id: workspaceId,
      },
    })

    if (response.data.success) {
      commit('SET_TARIFF', response.data.tariff)
      commit('SET_TRANSACTIONS', response.data.transactions)
      commit('SET_CARDS', response.data.cards)
    }

    return response
  },
  async getPlansIntoState ({ commit }, workspaceId) {
    const params = { workspace_id: workspaceId }
    const response = await axios.get('billing/payment/get-tariffs', { params })

    if (response.data.success) {
      commit('SET_PLANS', response.data.tariffs)
    }
  },
  async upgradePlan ({ rootState, state }) {
    const response = await axios.post('billing/payment/register', {
      tariff_id: state.tariffId,
      period: state.period,
      peoples: state.peoples,
      workspace_id: rootState.workspace.currentWorkspaceId,
    })

    if (response.data.success) {
      useKriptid(2, 'event', 'subWentToPayment')
      window.open(response.data.redirectUrl)
    }
  },
  async unsubscribe (context, workspaceId) {
    const response = await axios.post('billing/payment/cancel-subscription', {
      workspace_id: workspaceId,
    })

    if (response.data.success) {
      window.location.reload(true)
    }

    return response
  },
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
}
