<template>
  <VAuthContainer>
    <div class="text-5 mb-4">
      {{ $t('Registration') }}
    </div>

    <form
      novalidate
      class="sign-up-form"
      @submit.prevent="createAccount"
    >
      <VInput
        v-for="input in inputs"
        :key="input.name"
        :name="input.nameCamelCase"
        :type="input.type"
        :placeholder="$t(input.name)"
        :value="form[input.nameCamelCase]"
        :generalError="rulesForGeneralError"
        :disabled="inputDisabled"
        :required="input.required"
        class="sign-up-form__input"
        @change-input-value="changeForm($event, input.nameCamelCase)"
        @input-errors="onInputErrors"
        @show-error="onBlurInputWithError"
        @hide-error="onFocusInputWithError"
      >
        <component
          v-if="input.icon"
          :is="input.icon"
        />
      </VInput>

      <ErrorsLog
        :errorsShowed="errorsShowed"
        :errors="errors"
        :generalError="generalError"
        :booleanRulesForGeneralError="booleanRulesForGeneralError"
        class="sign-up-form__errors"
        @error-enter="erorrEnter"
        @error-after-leave="errorAfterLeave"
        @general-error-enter="generalErrorEnter"
        @general-error-after-leave="generalErrorAfterLeave"
      />

      <VButton
        :isLoading="isLoading"
        class="sign-up-form__button"
      >
        {{ $t('Create account') }}
      </VButton>
    </form>
  </VAuthContainer>
</template>

<script>
import { mapState, mapMutations, mapActions } from 'vuex'
import { useKriptid } from '@/utils/helpers'
import { errorsLog } from '@/mixins/errorsLog'
import { authForms } from '@/mixins/authForms'
import { validate } from '@/utils/validate'
import ErrorsLog from '@/components/auth/ErrorsLog'
import VAuthContainer from '@/components/ui/Auth/VAuthContainer'
import VInput from '@/components/ui/Auth/VInput'
import VButton from '@/components/ui/Auth/VButton'
import IconUser from '@/assets/icons/user9.svg'
import IconEmail from '@/assets/icons/contact-us.svg'
import IconLock from '@/assets/icons/lock9.svg'

const formTemplate = {
  userName: null,
  email: null,
  newPassword: null,
  code: null,
}

export default {
  name: 'SignUpForm',
  components: {
    ErrorsLog,
    VAuthContainer,
    VInput,
    VButton,
    IconUser,
    IconEmail,
    IconLock,
  },
  mixins: [
    errorsLog,
    authForms,
  ],

  data: () => ({
    form: { ...formTemplate },
    inputs: [
      {
        name: 'user name',
        nameCamelCase: 'userName',
        icon: 'IconUser',
        required: true,
      },
      {
        name: 'Email',
        nameCamelCase: 'email',
        type: 'email',
        icon: 'IconEmail',
        required: true,
      },
      {
        name: 'Password',
        nameCamelCase: 'newPassword',
        type: 'password',
        icon: 'IconLock',
        required: true,
      },
      {
        name: 'Code',
        nameCamelCase: 'code',
        icon: 'IconLock',
        required: true,
      },
    ],
  }),
  computed: {
    ...mapState('general', [
      'language',
    ]),

    statusAllFields () {
      return this.form.userName &&
        this.form.email &&
        this.form.newPassword &&
        this.form.code &&
        this.generalError.text === validate.generalErrors.allHighlightedFieldsMustBeFilled
    },
    formIsValid () {
      return this.form.userName &&
        this.form.email &&
        this.form.newPassword &&
        this.form.code &&
        !this.errors.length
    },
  },

  methods: {
    ...mapMutations('auth', [
      'SET_EMAIL',
      'SET_EMAIL_SEND_FLAG',
      'SET_EMAIL_LINK',
    ]),
    ...mapActions('auth', [
      'signUp',
    ]),

    async createAccount () {
      if (!this.formIsValid) {
        return this.setGeneralError(true, validate.generalErrors.allHighlightedFieldsMustBeFilled)
      }

      this.setFormLoadingState(true)

      try {
        const response = await this.signUp({
          username: this.form.userName,
          email: this.form.email,
          password: this.form.newPassword,
          confirmPassword: this.form.newPassword,
          code: this.form.code,
          time_zone: Intl.DateTimeFormat().resolvedOptions().timeZone,
          lang: this.language,
        })

        if (!response.data.success) {
          this.setFormLoadingState(false)
          this.setGeneralError(true, validate.generalErrors.tryAgain)
        }

        this.SET_EMAIL(this.form.email)
        this.SET_EMAIL_LINK(this.form.email.split('@')[1])
        this.SET_EMAIL_SEND_FLAG(true)

        useKriptid(1, 'event', 'mainRegistration', { source: 'main' })

        this.$router.push({
          name: 'confirm-email',
          params: { fromSignUp: true },
        })
      } catch (e) {
        this.setFormLoadingState(false)

        const error = e.response
          ? e.response.data.message
          : e.message

        switch (error) {
          case 'Network Error':
            this.setGeneralError(true, validate.generalErrors.connectionFalse)
            break
          case 'The given data was invalid.':
            this.setGeneralError(true, validate.generalErrors.emailAlreadyRegistered)
            break
          case 'You must guest':
            document.location.reload()
            break
          default: this.setGeneralError(true, validate.generalErrors.tryAgain)
        }
      }
    },
  },

  metaInfo () {
    return {
      title: this.$t('Registration'),
    }
  },
  i18n: {
    messages: {
      'en': {
        'First name': 'First name',
        'Last name': 'Last name',
        'user name': 'user name',
        'Code': 'User authentification',
        'Email': 'Email',
        'Password': 'Password',
        'Create account': 'Create account',
        'Registration': 'Registration',
      },
      'ru': {
        'First name': 'Имя',
        'Last name': 'Фамилия',
        'user name': 'Имя',
        'Code': 'Секретный Код',
        'Email': 'Электронная почта',
        'Password': 'Пароль',
        'Create account': 'Создать аккаунт',
        'Registration': 'Регистрация',
        'SignUp': 'Регистрация',
      },
    },
  },
}
</script>

<style lang="scss" scoped>
.sign-up-form {
  //display: grid;
  //grid-template-columns: repeat(2, 1fr);
  //grid-column-gap: 10px;
}

.sign-up-form__input {
  grid-column: span 2;
  margin-bottom: 20px;

  &:nth-child(1),
  &:nth-child(2) {
    grid-column: span 1;
  }
}

.sign-up-form__errors,
.sign-up-form__button {
  grid-column: span 2;
}
</style>
