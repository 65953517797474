<template>
  <div class="px-3 pt-2 home">
    <div class="border bg-light shadow-sm py-2 px-2 mb-3 mx-0 rounded-0">
      <div class="text-4 text-bold text-left">
        {{ item.title }}<span v-if="item.accountId">
          {{',   AccountId: ' + item.accountId}}
        </span>
      </div>
      <div class="text-4 text-left text-lighter">
        <span v-if="item.accountCurrency">
          {{ $t('Real') }} ({{ item.accountCurrency }})
        </span>
        <span v-if="item.accountServer">
          , {{ item.accountServer }}
        </span>
        <span v-if="item.accountLeverage">
          , 1:{{ item.accountLeverage }}
        </span>
        <span v-if="item.terminalName">
          , {{ item.terminalName }}
        </span>
      </div>
    </div>
    <div class="panels mr-2 mb-3">
      <div class="left-panel py-2 px-0 pb-4 border bg-light shadow">
        <b-nav tabs fill>
          <b-nav-item v-for="(value, key) in navItems_leftPanel"
                      :active="(navIndex_leftPanel == key)"
                      @click="navIndex_leftPanel = key"
          >
            {{ value }}
          </b-nav-item>
        </b-nav>
        <div class="bg-white px-3 pt-3"
             style="height: 98%;"
             v-if="navIndex_leftPanel == 1"
        >
          <div v-for="it in stats_leftPanel">
            <hr class="pt-2 pb-0 mb-0"
                v-if="it.value == 'line'"
            />
            <div class="d-flex justify-content-between pt-2"
                 v-else
            >
              <div class="text-4"
                   :class="it.class"
              >
                {{ it.title }}:
              </div>
              <div :class="it.class_value">
                {{ it.value }}
              </div>
            </div>
          </div>
        </div>
        <div class="bg-white px-3 pt-3"
             style="height: 98%;"
             v-else-if="navIndex_leftPanel == 2"
        >
          <div v-for="it in common_leftPanel">
            <hr class="pt-2 pb-0 mb-0"
                v-if="it.value == 'line'"
            />
            <div class="d-flex flex-column justify-content-between align-items-start pt-2"
                 v-else-if="it.key == 'description'"
            >
              <div class="text-4">
                {{ it.title }}:
              </div>
              <div>
                {{ it.value }}
              </div>
            </div>

            <div class="d-flex justify-content-between pt-2"
                 v-else
            >
              <div class="text-4"
                   :class="it.class"
              >
                {{ it.title }}:
              </div>
              <div :class="it.class_value">
                {{ it.value }}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="border bg-light shadow right-panel"
        id="rightPanel"
      >
        <div class="pt-2">
          <b-nav tabs>
            <b-nav-item v-for="(value, key) in navItems"
                        :active="(navIndex == key)"
                        @click="fillChartData(parseInt(key))"
            >
              {{ value }}
            </b-nav-item>
          </b-nav>
        </div>
        <div class="bg-white pt-3 px-2 chart" style="height: 92%;">
          <LineChart
            :chartData="chartData"
            :options="options"
            :responsive="true"
            :chartResize="chartResize"
            @update:chartResize="chartResize = $event"
          />
          <div class="hint_chart text-3 pt-2">
            {{ $t('Flip the phone over if not completely visible') }}
          </div>
        </div>
      </div>
    </div>
    <OrdersRobot
      :robotId="id"
    />
  </div>
</template>

<script>
import LineChart from '@/components/chart/LineChart'
import OrdersRobot from '@/components/chart/OrdersRobot'
import { mapState, mapActions } from 'vuex'

export default {
  name: 'Group',
  components: {
    LineChart,
    OrdersRobot,
  },
  props: {
    robotId: {
      default: '',
    },
  },

  data () {
    return {
      id: '',
      navIndex_leftPanel: 1,
      navIndex: 0,
      chartData: {
        datasets: [],
      },

      chartResize: false,
      options: {
        legend: {
          display: true,
          position: 'bottom',
        },
        responsive: true,
        maintainAspectRatio: false,
        scales: {
          yAxes: [{
            display: true,
            ticks: {
              beginAtZero: false,
              callback: (value) => {
                if ((Math.abs(value) % 1000 == 0) && (Math.abs(value) > 1000)) {
                  return (parseInt(value) / 1000) + 'К$'
                } else {
                  return value + '$'
                }
              },
            },
          }],
          xAxes: [{
            display: true,
            ticks: {
              beginAtZero: false,
              callback: (value, index, ticks) => {
                if (((index % 5) == 0) || (index == ticks.length - 1)) {
                  return value
                } else return ''
              },
            },
          }],
        },
      },
      lastTime: '',
      time: new Date().getTime(),
    }
  },

  computed: {
    ...mapState('chart', [
      'charts',
      'times',
      'item',
    ]),

    navItems_leftPanel () {
      return {
        1: this.$t('Statistics'),
        2: this.$t('General'),
      }
    },
    navItems () {
      return {
        0: this.$t('Height'),
        1: this.$t('Balance1'),
        2: this.$t('Profit'),
        3: this.$t('Drawdown'),
      }
    },

    stats_leftPanel () {
      const options = {
        month: 'long',
        day: 'numeric',
      }
      if (this.item.tradingInfo) {
        return [
          {
            title: this.$t('Profit'),
            value: this.charts.lastProfitAllTime + '%',
            class: 'text-bold text-underline',
            class_value: 'text-green text-bold',
          },
          {
            title: this.$t('Abs. Win'),
            value: '$' + this.charts.lastProfitPercentAllTime,
            class: 'text-underline',
            class_value: 'text-green',
          },
          {
            title: this.$t('Drawdown'),
            value: (this.item.tradingInfo ? this.item.tradingInfo.profit : '0') + '%',
          },
          {
            value: 'line',
          },
          {
            title: this.$t('Balance1'),
            value: '$' + (this.item.tradingInfo.balance ? this.item.tradingInfo.balance : '0'),
          },
          {
            title: this.$t('Means'),
            value: '$' + this.item.tradingInfo.equity,
          },
          {
            title: this.$t('Margin'),
            value: ((this.item.tradingInfo.marginLevel) ? this.item.tradingInfo.marginLevel : '0') + '%',
          },
          {
            title: this.$t('Minimum drawdown'),
            value: (this.charts.minProfitDate ? '(' + new Date(this.charts.minProfitDate).toLocaleString('ru', options) + ') ' : '') + '$' + (this.charts.minProfit ? this.charts.minProfit : '0'),
          },
          {
            title: this.$t('Profit'),
            value: '$' + this.item.tradingInfo.profitMonth,
            class_value: 'text-green',
          },
          // {
          //   title: 'Процент',
          //   value: '-$11,878.37 __??',
          // },
          {
            value: 'line',
          },
          {
            title: this.$t('Deposits'),
            value: '$' + this.charts.deposits,
          },
          {
            title: this.$t('Withdrawals of funds'),
            value: '$' + this.charts.withdrawals,
          },
          {
            value: 'line',
          },
          {
            title: this.$t('Updated'),
            value: this.lastTime,
          },
          // {
          //   title: 'Отслеживание',
          //   value: '0',
          // },
        ]
      } else {
        return []
      }
    },

    common_leftPanel () {
      if (this.item) {
        return [
          {
            title: this.$t('Description'),
            value: this.$t('Нет описания.'),
            key: 'description',
          },
          {
            value: 'line',
          },
          // {
          //   title: 'Просмотры',
          //   value: '279',
          // },
          {
            title: this.$t('Broker'),
            value: this.item.accountServer,
          },
          {
            title: this.$t('Credit number'),
            value: '1:' + (this.item.accountLeverage ? this.item.accountLeverage : '1'),
          },
          {
            title: this.$t('Type'),
            value: this.item.real ? this.$t('Real') : this.$t('Cent'),
          },
          // {
          //   title: 'Система',
          //   value: 'Технический??',
          // },
          // {
          //   title: 'Трейдинг',
          //   value: 'Автоматически??',
          // },
          // {
          //   title: 'Начал',
          //   value: 'Oct 07, 2020??',
          // },
          {
            title: this.$t('Status'),
            value: (this.item.link ? (this.item.link.isPublic ? this.$t('Public') : this.$t('Not public')) : this.$t('Not public')),
          },
          // {
          //   title: 'Часовой пояс',
          //   value: 'GMT +2??',
          // },
        ]
      } else {
        return []
      }
    },
  },

  watch: {
    async robotId () {
      if (this.robotId != '') {
        this.id = this.robotId
      } else {
        this.id = this.$route.params.id
      }
      await this.getChart(this.id)
      await this.getRobots()
      this.fillChartData(this.navIndex)
    },
  },

  methods: {
    ...mapActions('chart', [
      'getChart',
    ]),
    ...mapActions('robot', [
      'getRobots',
    ]),
    ...mapActions('general', [
      'setLoading',
    ]),

    resize () {
      this.chartResize = true
    },
    fillChartData (index) {
      var chart = {
        datasets: [],
      }
      chart.labels = this.times
      switch (index) {
        case 0:
          chart.datasets = [
            {
              label: this.$t('Profit percent all time'),
              type: 'line',
              data: this.charts.profitPercentAllTime,
              backgroundColor: 'rgba(0, 0, 0, 0)',
              borderColor: 'rgb(229,31,31)',
            },
          ]
          this.options.scales.yAxes[0].ticks.callback = (value) => {
            return value + '%'
          }
          break
        case 1:
          chart.datasets = [
            {
              label: this.$t('Balance1'),
              type: 'line',
              data: this.charts.balances,
              backgroundColor: 'rgba(0, 0, 0, 0)',
              borderColor: 'rgb(229,31,31)',
            },
            {
              label: this.$t('Equity'),
              type: 'line',
              data: this.charts.equities,
              backgroundColor: 'rgba(0, 0, 0, 0)',
              borderColor: 'rgb(229,186,31)',
            },
            {
              label: this.$t('Profit'),
              type: 'line',
              data: this.charts.profit,
              backgroundColor: 'rgba(0, 0, 0, 0)',
              borderColor: 'rgb(229,94,31)',
            },
            {
              label: this.$t('ProfitMonth'),
              type: 'bar',
              data: this.charts.profitMonth,
              backgroundColor: 'rgb(33,131,20)',
            },
          ]
          this.options.scales.yAxes[0].ticks.callback = (value) => {
            if ((Math.abs(value) % 1000 == 0) && (Math.abs(value) > 1000)) {
              return (parseInt(value) / 1000) + 'К$'
            } else {
              return value + '$'
            }
          }
          break
        case 2:
          chart.datasets = [
            {
              label: this.$t('Profit all time'),
              type: 'line',
              data: this.charts.profitAllTime,
              backgroundColor: 'rgba(0, 0, 0, 0)',
              borderColor: 'rgb(229,31,31)',
            },
          ]
          this.options.scales.yAxes[0].ticks.callback = (value) => {
            return value + '$'
          }
          break
      }
      if (chart.datasets.length > 0) {
        chart.datasets[0].backgroundColor = 'rgba(0, 0, 0, 0)'
        chart.datasets[0].borderColor = 'rgb(229,31,31)'
      }
      this.chartData = chart
      this.navIndex = index
    },

    setLastTime () {
      const time_ = new Date(new Date().getTime() - this.time)
      if (time_.getTime() / 1000 == 0) {
        this.lastTime = this.$t('Last Update: now')
      }
      else if (time_.getTime() / 1000 >= 20) {
        this.lastTime = new Date(this.time).toLocaleString()
      } else {
        this.lastTime = Math.round(parseInt(time_.getTime()) / 1000) + ' ' + this.$t('second age')
      }
    },
  },

  async created () {
    window.addEventListener('resize', this.resize)
    if (this.robotId != '') {
      this.id = this.robotId
    } else {
      this.id = this.$route.params.id
    }
    await this.getChart(this.id)
    await this.getRobots()
    this.fillChartData(this.navIndex)
    this.setLoading(false)

    this.time = new Date().getTime()
    this.setLastTime()
    this.intervalLastTime = setInterval(() => {
      this.setLastTime()
    }, 4000)
  },
  destroyed () {
    window.removeEventListener('resize', this.resize)
  },

  i18n: {
    messages: {
      'ru': {
        'Balance1': 'Баланс',
        'ProfitMonth': 'Месячный профит',
        'Equity': 'Средства',
        'Profit': 'Просадка',
        'Flip the phone over if not completely visible': 'Переверните телефон, если не видно полностью',
        'Profit percent all time': 'Рост, %',
        'Profit all time': 'Рост, $',
        'Real': 'Реальный',
        'Cent': 'Центовый',
      },
    },
  },
}
</script>

<style lang="scss" scope>
.home {
  overflow-y: auto;
}
.panels {
  display: grid;
  grid-template-columns: 400px auto;
  width: 100%;
  padding: 0px;
  gap: 16px;
  @media (max-width: 850px) {
    grid-template-rows: auto 1fr;
    grid-template-columns: 100%;
  }
}
.left-panel {
  width: 100%;
  max-width: 400px;
  justify-self: center;
}
.chart {
  position: relative;
  //width: 100%;
}
@media (min-width: 850px) {
  .hint_chart {
    display: none;
  }
}

.text-green {
  color: #09D339;
}
.text-bold {
  font-weight: bold;
}
.text-lighter {
  font-weight: 300;
}
.text-underline {
  text-decoration: underline dotted;
  text-underline-offset: 4px;
}
</style>
