<template>
  <div class="d-flex align-content-center py-2 mt-5 ml-4">
    <b-button class="mx-3"
              variant="primary"
              @click="linkTelegram"
              v-if="!registered"
    >
      {{ $t('Link Telegram') }}
    </b-button>

    <b-button class="mx-3"
              v-else
    >
      {{ $t('Linked Telegram') }}
    </b-button>

    <b-link v-show="telegramURL"
            :href="telegramURL"
    >
      {{telegramURL}}
    </b-link>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'

export default {
  name: 'profile',

  data () {
    return {
      telegramURL: '',
    }
  },

  computed: {
    ...mapState('telegram', [
      'data',
    ]),
    ...mapState('auth', [
      'registered', 'telegramId',
    ]),
  },

  methods: {
    ...mapActions('telegram', [
      'getTelegramURL',
    ]),
    ...mapActions('general', [
      'setLoading',
    ]),

    async linkTelegram () {
      const resp = await this.getTelegramURL()
      this.telegramURL = resp
    },
  },

  created () {
    setTimeout(() => {
      this.setLoading(false)
    }, 200)
  },

  i18n: {
    messages: {
      'en': {
        'Link Telegram': 'Link Telegram',
        'Linked Telegram': 'Linked Telegram',
      },
      'ru': {
        'Link Telegram': 'Привязать Телеграмм',
        'Linked Telegram': 'Телеграмм привязан',
      },
    },
  },
}
</script>

<style scoped>

</style>
