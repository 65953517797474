// import { useKriptid } from '@/utils/helpers'
import { requestAdvancedRobot, requestOrdersRobot } from '@/requestManager/RobotRequests'
// import Vue from 'vue'

const state = {
  charts: [],
  orders: [],
  lastPage: 0,
  times: [],
  item: {},
  isTabActive: true,
  timezone: null,
}

const getters = {
  hasCompletedTip: state => robots => {
    return ''
  },
}

const mutations = {
  SET_CHART_DATA (state, chart) {
    state.times = chart.times
    const options = {
      day: 'numeric',
      month: 'numeric',
      year: '2-digit',
    }
    console.log(state)
    state.times = state.times.map((item) => {
      return new Date(item * 1000).toLocaleString('ru', options)
    })
    state.charts = chart
  },
  SET_ORDERS_DATA (state, params) {
    state.orders = params.data
    state.lastPage = params.pagination.lastPage
    const options = {
      timeStyle: 'short',
      dateStyle: 'short',
    }
    state.orders.forEach((value, index, self) => {
      if (self[index].close_time != 0) {
        self[index].duration_ = self[index].close_time - self[index].open_time
        var days = ~~(self[index].duration_ / (24 * 3600))
        if (days > 0) {
          self[index].days = days
          self[index].duration_ = self[index].duration_ - (self[index].days * 24 * 3600)
        } else {
          self[index].days = '-'
        }
        var hours = ~~(self[index].duration_ / 3600)
        if (hours > 0) {
          self[index].hours = hours
          self[index].duration_ = self[index].duration_ - (self[index].hours * 3600)
        } else {
          self[index].hours = '-'
        }
        var minutes = ~~(self[index].duration_ / 60)
        if (minutes > 0) {
          self[index].minutes = minutes
          self[index].duration_ = self[index].duration_ - (self[index].hours * 60)
        } else {
          self[index].minutes = '-'
        }
        var seconds = self[index].duration_
        if (seconds > 0) {
          self[index].seconds = minutes
        } else {
          self[index].seconds = '-'
        }
        self[index].duration_ = ''
      } else {
        self[index].duration = '-'
      }
      Object.keys(self[index]).map((key) => {
        if (key == 'open_time' || key == 'close_time') {
          if (self[index][key] != 0) {
            self[index][key] = new Date(self[index][key] * 1000).toLocaleString('ru', options)
          } else {
            self[index][key] = '-'
          }
        }
      })
    })
  },
  SET_ITEM_DATA (state, item) {
    state.item = item
    state.item.graphics = null
  },
}

const actions = {
  async getChart ({ state, commit, dispatch }, params) {
    const response = await requestAdvancedRobot(params)

    if ((response.data.success)) {
      const resp = await response.data.data

      commit('SET_CHART_DATA', resp.graphics)
      commit('SET_ITEM_DATA', resp)
    }

    return response.data
  },

  async getOrders ({ state, commit, dispatch }, params) {
    const response = await requestOrdersRobot(params[0], params[1])

    if ((response.data.success)) {
      const resp = await response.data

      commit('SET_ORDERS_DATA', resp)
    }

    return response.data
  },
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
}
