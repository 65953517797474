// import { useKriptid } from '@/utils/helpers'
import { requestGroups, requestGroup, requestUpdateGroup, requestCreateGroup, requestDeleteGroup } from '@/requestManager/GroupRequests'
import Vue from 'vue'

const state = {
  groups: [],
  isTabActive: true,
  timezone: null,
}

const getters = {
  hasCompletedTip: state => robots => {
    return ''
  },
}

const mutations = {
  SET_GROUPS_DATA (state, groups) {
    state.groups = groups
  },

  SET_GROUP_DATA (state, group) {
    var index_ = -1
    state.groups.some(function (item, index) {
      index_ = index
      return item.id == group.id
    })
    if (index_ != -1) {
      Vue.set(state.groups, index_, group)
    }
  },

  APPEND_GROUP_DATA (state, group) {
    if (state.groups.length == 0) {
      state.groups = []
    }
    state.groups.push(group)
  },

  DELETE_GROUP_DATA (state, index) {
    if (state.groups.length != 0) {
      var index_ = -1
      state.groups.some(function (item, index) {
        index_ = index
        return item.id == index
      })
      if (index_ != -1) {
        state.groups.splice(index_, 1)
        state.groups = state.groups
      }
    }
  },

}

const actions = {
  async getGroups ({ state, commit, dispatch }) {
    const response = await requestGroups()

    if ((response.data.success)) {
      const resp = await response.data.data

      commit('SET_GROUPS_DATA', resp)
    }

    return response.data
  },

  async getGroup ({ state, commit, dispatch }, id) {
    const response = await requestGroup(id)

    const resp = await response.data.data
    if ((response.data.success)) {
      commit('SET_GROUPS_DATA', resp)
    }

    return resp
  },

  async createGroup ({ state, commit, dispatch }, params) {
    const response = await requestCreateGroup({
      'title': params[0],
      'traderAccountsList': params[1],
      'is_public': params[2] ? 1 : 0,
      'link': params[3],
    })

    const resp = await response.data
    if (resp.success) {
      commit('APPEND_GROUP_DATA', resp.data)
    }
    return resp.success
  },

  async deleteGroup ({ state, commit, dispatch }, index) {
    const response = await requestDeleteGroup(index)

    const resp = await response.data
    if (resp.success) {
      commit('DELETE_GROUP_DATA', index)
    }
    return resp.success
  },

  async updateGroup ({ state, commit, dispatch }, params) {
    const response = await requestUpdateGroup(params[0], {
      'title': params[1],
      'traderAccountsList': params[2],
      'is_public': params[3] ? 1 : 0,
      'link': params[4],
    })

    const resp = await response.data
    if (resp.success) {
      commit('SET_GROUP_DATA', resp.data)
    }
    return resp.success
  },

}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
}
