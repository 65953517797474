// import { useKriptid } from '@/utils/helpers'
import { requestGetNotifications, requestSelectValues, requestCreateNotify, requestDeleteNotify, requestUpdateNotify } from '@/requestManager/NotificationRequests'
import Vue from 'vue'

const state = {
  notifications: [],
  selectValues: [],
  isTabActive: true,
  timezone: null,
}

const getters = {
  hasCompletedTip: state => robots => {
    return ''
  },
}

const mutations = {
  SET_NOTIFICATIONS_DATA (state, notifications) {
    state.notifications = notifications
  },

  SET_SELECT_VALUES_DATA (state, selectValues) {
    state.selectValues = selectValues
    for (var el in state.selectValues) {
      el = Object.fromEntries(
        Object.entries(el).map(([key, value]) => [
          key,
          {
            text: value,
            value: key,
          },
        ]))
    }
  },

  SET_NOTIFICATION_DATA (state, notify) {
    var index_ = -1
    state.notifications.some(function (item, index) {
      index_ = index
      return item.id == notify.id
    })
    if (index_ != -1) {
      Vue.set(state.notifications, index_, notify)
      state.notifications = state.notifications
    }
  },

  APPEND_NOTIFICATION_DATA (state, notify) {
    if (state.notifications.length == 0) {
      state.notifications = []
    }
    state.notifications.push(notify)
  },

  DELETE_NOTIFICATION_DATA (state, index) {
    if (state.notifications.length != 0) {
      var index_ = -1
      state.notifications.some(function (item, index) {
        index_ = index
        return item.id == index
      })
      if (index_ != -1) {
        state.notifications.splice(index_, 1)
        state.notifications = state.notifications
      }
    }
  },

}

const actions = {
  async getNotifications ({ state, commit, dispatch }) {
    const response = await requestGetNotifications()

    if ((response.data.success)) {
      const resp = await response.data.data

      commit('SET_NOTIFICATIONS_DATA', resp)
    }

    return response.data
  },

  async createNotify ({ state, commit, dispatch }, params) {
    const response = await requestCreateNotify({
      'trader_account_id': params.traderId,
      'parameter': params.parameter,
      'direction': params.direction,
      'value': params.value,
      'valueStop': params.valueStop,
      'color': params.color,
      'step': params.step,
      'tg': params.tg ? '1' : '0',
      'tg_timeout': (params.tgTimeOut == '') ? '0' : params.tgTimeOut,
      'email': params.email ? '1' : '0',
      'email_timeout': (params.emailTimeOut == '') ? '0' : params.emailTimeOut,
      'status': params.status,
    })

    const resp = await response.data
    if (resp.success) {
      commit('APPEND_NOTIFICATION_DATA', resp.data)
    }
    return resp.success
  },

  async deleteNotify ({ state, commit, dispatch }, index) {
    const response = await requestDeleteNotify(index)

    const resp = await response.data
    if (resp.success) {
      commit('DELETE_NOTIFICATION_DATA', index)
    }
    return resp.success
  },

  async updateNotify ({ state, commit, dispatch }, params) {
    const response = await requestUpdateNotify(params[0], {
      'trader_account_id': params[1].traderId,
      'parameter': params[1].parameter,
      'direction': params[1].direction,
      'value': params[1].value,
      'valueStop': params[1].valueStop,
      'color': params[1].color,
      'step': params[1].step,
      'tg': params[1].tg ? '1' : '0',
      'tg_timeout': (params[1].tgTimeOut == '') ? '0' : params[1].tgTimeOut,
      'email': params[1].email ? '1' : '0',
      'email_timeout': (params[1].emailTimeOut == '') ? '0' : params[1].emailTimeOut,
      'status': params[1].status,
    })

    const resp = await response.data
    if (resp.success) {
      commit('SET_NOTIFICATION_DATA', resp.data)
    }
    return resp.success
  },

  async getSelectValues ({ state, commit, dispatch }) {
    const response = await requestSelectValues()

    if ((response.data.success)) {
      const resp = await response.data.data

      commit('SET_SELECT_VALUES_DATA', resp)
    }

    return response.data
  },
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
}
