import axios from 'axios'
import { services } from '@/utils/constants/service'

const state = {
  chooseServiceModalOpened: false,
  currentServiceId: services.TASK_MANAGER,
}

const mutations = {
  HIDE_CHOOSE_SERVICE_MODAL (state) {
    state.chooseServiceModalOpened = false
  },
  TOGGLE_CHOOSE_SERVICE_MODAL (state) {
    state.chooseServiceModalOpened = !state.chooseServiceModalOpened
  },

  SET_CURRENT_SERVICE (state, service) {
    state.currentServiceId = service
  },
}

const actions = {
  async setService ({ commit }, service) {
    commit('SET_CURRENT_SERVICE', service)

    service = service.toString()
    await axios.post('user/update/last-active-service', { service })
  },
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
}
