<template>
  <b-form-group
    :disabled="disabled"
    @mouseenter="onMouseEnterInput"
    @mouseleave="onMouseLeaveInput"
    @click.self="onClickInput"
    :label-for="name"
    class="text-left">
  <!--    :label="$attrs.placeholder"-->

    <b-input-group>
      <b-form-input
        :id="name"
        v-bind="$attrs"
        v-model="model"
        :type="typeInput"
        ref="input"
        :class="{ 'v-input__input': showPasswordIcon }"
        @focus="onFocus"
        @blur="onBlur"
      />
      <b-input-group-addon>
        <b-input-group-text
          v-if="showPasswordIcon"
          class="pr-0 bg-white v-input__password-addon"
        >
          <div
            class="v-input__password-icon"
            @mousedown.stop="onMouseDown"
            @mouseup.stop="onMouseUp"
          >
            <IconHide v-if="!passwordVisible" />
            <IconView v-else />
          </div>
        </b-input-group-text>
      </b-input-group-addon>
    </b-input-group>
  </b-form-group>
</template>

<script>
import { validate } from '@/utils/validate'
import IconView from '@/assets/icons/view.svg'
import IconHide from '@/assets/icons/hide.svg'

export default {
  name: 'VInput',
  components: {
    IconView,
    // eslint-disable-next-line vue/no-unused-components
    IconHide,
  },

  props: {
    name: {
      type: String,
      default: null,
      validator (val) {
        return [
          'username',
          'email',
          'password',
          'newPassword',
          'firstName',
          'lastName',
        ].indexOf(val) !== -1
      },
    },
    svgColorsType: {
      type: String,
      default: 'default',
      validator (val) {
        return ['default'].indexOf(val) !== -1
      },
    },
    value: null,
    generalError: {
      type: Boolean,
      default: null,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    required: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    classHover: false,
    classFocus: false,
    optionsTemplate: {
      add: 'add',
      delete: 'delete',
    },
    error: {
      text: '',
      option: null,
    },
    passwordVisible: false,
    validated: false,
  }),
  computed: {
    model: {
      get () {
        return this.value
      },
      set (value) {
        this.changeInputValue(value)
      },
    },
    vInputClasses () {
      return {
        'v-input': true,
        hover: this.classHover,
        focus: this.classFocus,
        filled: this.value,
        error: this.required && (this.error.option === this.optionsTemplate.add || this.generalError) && !this.classFocus,
        disabled: this.disabled,
      }
    },
    showPasswordIcon () {
      const name = this.name

      return this.value &&
        (name === 'password' || name === 'newPassword')
    },
    typeInput () {
      const name = this.name

      return ((name === 'password' || name === 'newPassword') && this.passwordVisible == false) ? 'password' : 'text'
    },
  },
  watch: {
    value () {
      const validateTemplate = validate[this.name]

      this.validateField(validateTemplate.reg, validateTemplate.textError)
    },
  },

  methods: {
    // События
    onFocus () {
      this.classFocus = true

      if (this.error.text.length) {
        this.$emit('hide-error', this.error.text)
      }
    },
    onBlur () {
      this.classFocus = false

      if (this.error.text.length) {
        this.$emit('show-error', this.error.text)
      }
    },
    onMouseEnterInput () {
      this.classHover = true
    },
    onMouseLeaveInput () {
      this.classHover = false
    },
    onMouseDown () {
      this.passwordVisible = true
    },
    onMouseUp () {
      this.passwordVisible = false
    },
    onClickInput () {
      this.$refs.input.focus()
    },

    // Прочее
    changeError (textError, option) {
      this.error = {
        text: textError,
        option: option,
      }
    },
    validateField (reg, textError) {
      if (this.value && !reg.test(this.value)) {
        if (!(this.error.text === textError)) {
          this.changeError(textError, this.optionsTemplate.add)
          this.$emit('input-errors', {
            ...this.error,
            isVisible: false,
          })
        }
      } else {
        if (this.error.text === textError) {
          this.changeError('', null)
          this.$emit('input-errors', {
            text: textError,
            option: this.optionsTemplate.delete,
          })
        }
      }
    },
    changeInputValue (value) {
      this.$emit('change-input-value', value)
    },
  },
}
</script>

<style lang="scss" scoped>
.v-input {
  /*display: flex;*/
  /*align-items: center;*/
  padding-left: 5px;
  font-size: 14px;
  font-variation-settings: 'wght' 600;
  line-height: 100%;
  color: #A6ACBF;
  background-color: #FFF;
  border: 2px solid transparent;
  border-radius: 4.8px;
  transition: all .3s ease;
  cursor: text;
}

input::placeholder {
  color: #A6ACBF;
  transition: all .3s ease;
}

.slot {
  display: flex;
  align-items: center;
  padding-left: 4px;
  height: auto;
  width: 24px;
}

.default {
  svg {
    height: 100%;

    rect,
    path {
      fill: #A6ACBF;
    }
  }
}

.v-input__input {
  border-right: none;
}

.v-input__password-addon{
  border-radius: 4px;
  border: 1px solid #e6e9f4;
  border-left: none;
}

.v-input__password-icon {
  margin-right: 14px;
  cursor: pointer;
  user-select: none;

  rect,
  path {
    fill: #A6ACBF;
    transition: all .3s ease;
  }
}

.hover {
  color: #7B8193;
  background-color: #FBFBFC;

  input::placeholder {
    color: #7B8193;
  }

  .default {
    svg {
      rect,
      path {
        fill:#7B8193;
        transition: all .3s ease;
      }
    }
  }
}

.focus {
  color: #2E3038;
  background-color: #FFF;

  input::placeholder {
    color: #2E3038;
  }

  .default {
    svg {
      rect,
      path {
        fill: #6788F3;
        transition: all .3s ease;
      }
    }
  }
}

.disabled {
  color: #7B8193;

  svg {
    rect,
    path {
      opacity: .5;
    }
  }
}

.filled {
  color: #2E3038;

  .default {
    svg {
      rect,
      path {
        fill:#6788F3;
      }
    }
  }

  .hover {
    background-color: #FBFBFC;
  }
}

.error {
  background-color: #FFF;
  border: 2px solid #FF8888;
}
</style>
