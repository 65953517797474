import Vue from 'vue'
import VueRouter from 'vue-router'
import Index from '../views/robots/Index.vue'
import Groups from '../views/groups/Index.vue'
import Group from '../views/groups/Group.vue'
import Advisors from '../views/advisors/Index.vue'
import Public from '../views/a/Index.vue'
import SettingProfile from '../views/settings/Profile.vue'
import SettingNotification from '../views/settings/Notification.vue'
import Chart from '../views/robots/Chart.vue'

import authRoutes from './authRoutes'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Index',
    component: Index,
  },
  {
    path: '/robots',
    name: 'Robots',
    component: Index,
  },
  {
    path: '/groups',
    name: 'Groups',
    component: Groups,
  },
  {
    path: '/g/:id',
    name: 'Group',
    component: Group,
  },
  {
    path: '/advisors',
    name: 'Advisors',
    component: Advisors,
  },
  {
    path: '/a/:key',
    name: 'Public',
    component: Public,
  },
  {
    path: '/about',
    name: 'About',
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue'),
  },
  {
    path: '/settings/profile',
    name: 'settings-profile',
    component: SettingProfile,
  },
  {
    path: '/settings/notification',
    name: 'settings-notifications',
    component: SettingNotification,
  },
  {
    path: '/robots/:id',
    name: 'Chart',
    component: Chart,
  },
  ...authRoutes,
]

const router = new VueRouter({
  mode: 'history',
  routes,
})
export default router
