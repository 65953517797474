<template>
  <b-modal id="modalRobotSetting"
           :title="label"
           v-model="showModal"
           @hide="$emit('update:showModal', false)"
           :no-stacking="true"
           content-class="shadow"
           :size="size"
           :button-size="size"
           @ok="$emit('ok')"
           @cancel="$emit('cancel')"
  >
    <slot name="content"></slot>

    <template #modal-footer>
      <slot name="footer"></slot>
    </template>

  </b-modal>
</template>

<script>
export default {
  name: 'Modal',
  props: [
    'showModal',
    'label',
    'ok',
    'cancel',
    'size',
  ],
}
</script>

<style lang="scss" scoped>
.modal-backdrop {
  opacity: 0.5;
}

</style>
