<template>
  <div class="sign-in">
    <div class="sign-in__container">
            <SignInForm/>
    </div>
  </div>
</template>

<script>
import { defineLanguage } from '@/mixins/defineLanguage'
/* import AuthSocials from '@/components/auth/AuthSocials'; */
import SignInForm from '@/components/auth/signIn/SignInForm'
import VLink from '@/components/ui/Auth/VLink'
import {mapActions} from "vuex";

export default {
  name: 'SignIn',
  components: {
    /* AuthSocials, */
    SignInForm,
    VLink,
  },
  mixins: [
    defineLanguage,
  ],

  metaInfo () {
    return {
      title: this.$t('Sign In'),
    }
  },
  methods: {
    ...mapActions('general', [
      'setLoading',
    ]),
  },

  created() {
    this.setLoading(false)
  },

  i18n: {
    messages: {
      'en': {
        'SignIn': 'Continue to trade-fox',
        'Forgot password?': 'Forgot password?',
        'or log in with': 'or log in with',
        'Sign In': 'Sign In',

      },
      'ru': {
        'or log in with': 'или войдите через',
        'Sign In': 'Вход',
      },
    },
  },
}
</script>

<style lang="scss" scoped>
.sign-in {
  position: relative;
}

.sign-in__container {
  /*position: absolute;*/
  top: 0;
  width: 100%;
  max-width: 360px;
  text-align: center;
}

.sign-in__header {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  margin-bottom: 12px;
  padding: 0 20px;
  height: 25px;
}

.sign-in__header-text {
  font-size: 18px;
  font-weight: 600;
  font-variation-settings: 'wght' 600;
  line-height: 25px;
  color: #4D505E;
}

.sign-in__text {
  padding-top: 20px;
  margin-bottom: 20px;
  font-size: 14px;
  font-weight: 400;
  font-variation-settings: 'wght' 400;
  line-height: 19px;
  color: #A6ACBF;
}
</style>
