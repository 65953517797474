<template>
  <div class="home">
    <Group :group="{ 'title': title, 'traderAccounts': publicItem, }"
           :robots="publicItem"
           :time="time"
           :lastTime="lastTime"
           v-if="type == 'group'"
           not_setting
    />
    <Chart :robotId="robotId"
      v-if="type == 'robot'"
    />
  </div>
</template>

<script>
import Chart from '../robots/Chart'
import Group from '../../components/group/Group'
import { mapActions, mapState } from 'vuex'

export default {
  name: 'Public',
  components: {
    Group,
    Chart,
  },

  data () {
    return {
      time: new Date().getTime(),
      lastTime: '',
      robotId: '',
    }
  },

  computed: {
    ...mapState('publicKey', [
      'title',
      'type',
      'publicItem',
    ]),
  },

  methods: {
    ...mapActions('publicKey', [
      'getPublic',
    ]),
    ...mapActions('general', [
      'setLoading',
    ]),

    setLastTime () {
      const time_ = new Date(new Date().getTime() - this.time)
      if (time_.getTime() / 1000 == 0) {
        this.lastTime = this.$t('Last Update: now')
      }
      else if (time_.getTime() / 1000 >= 20) {
        this.lastTime = this.$t('Last Update') + ': ' + new Date(this.time).toLocaleString()
      } else {
        this.lastTime = this.$t('Last Update') + ': ' + Math.round(parseInt(time_.getTime()) / 1000) + ' ' + this.$t('second age')
      }
    },
  },

  async created () {
    if (await this.getPublic(this.$route.params.key)) {
      this.intervalGetRobots = setInterval(async () => {
        await this.getPublic(this.$route.params.key)
        this.time = new Date().getTime()
      }, 10000)
      this.time = new Date().getTime()

      this.robotId = this.publicItem.id
      this.setLastTime()
      this.intervalLastTime = setInterval(() => {
        this.setLastTime()
      }, 4000)
    }
    this.setLoading(false)
  },

  beforeDestroy () {
    if (this.intervalGetRobots) clearInterval(this.intervalGetRobots)
    if (this.intervalLastTime) clearInterval(this.intervalLastTime)
  },
}
</script>

<style scoped>
.home {
  overflow-y: auto;
}
</style>
