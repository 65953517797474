import axios from 'axios'

const GROUPS_ALL = '/api/groups'
const GROUP_INDEX = '/api/groups/show/'
const GROUP_UPDATE = '/api/groups/update/'
const GROUP_CREATE = '/api/groups/create/'
const GROUP_DELETE = '/api/groups/'
const GROUP_DELETE_1 = '/delete'

export const requestGroups = params => {
  return axios.get(GROUPS_ALL, params)
}

export const requestGroup = (id, params) => {
  return axios.get(GROUP_INDEX + id, params)
}

export const requestUpdateGroup = (index, params) => {
  return axios.put(GROUP_UPDATE + index, params)
}

export const requestCreateGroup = (params) => {
  return axios.post(GROUP_CREATE, params)
}

export const requestDeleteGroup = (index, params) => {
  return axios.delete(GROUP_DELETE + index + GROUP_DELETE_1, params)
}
