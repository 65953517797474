<template>
<!--  <div id="app">
    <div id="nav">
      <router-link to="/">Home</router-link> |
      <router-link to="/about">About</router-link> |
      <router-link to="/sign-in">Sign In</router-link>
    </div>
    <router-view/>
  </div>-->
  <div
      class="app-container"
      :class="{ 'dark-theme': theme === 'dark' }"
      id="app"
  >
    <div v-if="loading"
         class="loader"
    >
      <img
        class="logo"
        src="@/assets/icons/icon.png"
        width="150"
        height="150"
      />
      <div class="loader-title">
        Trade App
      </div>
    </div>
    <VueExtendLayouts />
  </div>
</template>
<script>
import { mapState, mapActions } from 'vuex'
import VueExtendLayouts from 'vue-extend-layout'

import '@/assets/styles/main.scss'

export default {
  name: 'App',
  components: {
    VueExtendLayouts,
  },

  computed: {
    ...mapState('user', [
      'user',
    ]),
    ...mapState('general', [
      'loading',
    ]),

    theme () {
      return this.user
        ? this.user.theme
        : 'light'
    },
  },

  methods: {
    ...mapActions('general', [
      'setLoading',
    ]),
  },

  watch: {
    $route (value) {
      this.setLoading(true)
    },
  },
}
</script>
<style lang="scss">
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700;900&display=swap');
#app {
  font-family: 'Roboto', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

#nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}

.loader {
  position: absolute;
  z-index: 99;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  align-content: center;
  justify-content: center;

  background-color: rgba(61, 162, 255, 0.85);

  .logo {
    margin-bottom: 30px;

    filter: drop-shadow(1px 1px 1px #666);
    animation-name: rotation;
    animation-duration: 3s;
    animation-iteration-count: infinite;
    animation-timing-function: linear;

    @keyframes rotation {
      0% {
        transform:rotate(0deg);
      }
      100% {
        transform:rotate(360deg);
      }
    }
  }

  .loader-title {
    font-size: 25px;
    color: #2c3e50;
    font-weight: 600;
  }
}

</style>
