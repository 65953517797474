<template>
  <b-jumbotron
    class="container jumbotron mt-0 mw-100 align-center p-0 shadow-sm"
    fluid
  >
    <div class="row">
      <div class="col-sm-11 col-10 cursor-pointer pb-2 pt-4"
           @click="$router.push({ path: '/g/' + item.id })"
      >
        <h4 class="text-left align-center">
          {{item.title}}
        </h4>
      </div>

      <div class="col-sm-1 col-2 pb-2 pt-4">
        <ContextMenu :id="'contextMenu_' + item.id"
                     class="pl-1 pl-md-2 pl-lg-0"
        >
          <template  #context-menu-content>
            <ContextMenuGroup @showModalSetting="showModal = true"
                              @deleteGroup="deletingGroup()"
            />

          </template>
        </ContextMenu>
      </div>
    </div>

    <SettingGroup
      :showModal.sync="showModal"
      @update:showModal="showModal = $event"
      :label="$t('Setting group')"
      :labelOK="$t('Save')"
      @ok="updateGroup_()"
      :id="item.id"
      :titleGroup.sync="titleGroup"
      :selected.sync="selected"
      :publicGroup.sync="publicGroup"
      :keyGroup.sync="keyGroup"
    />
  </b-jumbotron>
</template>

<script>
import ContextMenu from '../ui/ContextMenu'
import ContextMenuGroup from './menu/ContextMenuGroup'
import Modal from '../../components/ui/Modal'
import SettingGroup from './setting/SettingGroup'
import { mapActions, mapState } from 'vuex'

export default {
  components: {
    ContextMenu,
    ContextMenuGroup,
    Modal,
    SettingGroup,
  },
  name: 'Group',
  props: [
    'item',
  ],

  data () {
    return {
      showModal: false,
      titleGroup: this.item.title,
      selected: this.item.traderAccounts,
      publicGroup: false,
      keyGroup: '',
    }
  },

  computed: {
    ...mapState('robot', [
      'robots',
    ]),

    robotsOptions () {
      return this.robots.map((item) => {
        return ({
          title: item.title,
          id: item.id,
        })
      })
    },

    selectedRobots () {
      return this.selected.map((item) => {
        return (item.id)
      })
    },
  },

  methods: {
    ...mapActions('groups', [
      'getGroups',
      'updateGroup',
      'deleteGroup',
    ]),
    ...mapActions('robot', [
      'getRobots',
    ]),

    async updateGroup_ () {
      await this.updateGroup([
        this.item.id,
        this.titleGroup,
        this.selectedRobots,
        this.publicGroup,
        this.keyGroup,
      ]).then((response) => {
        if (response) {
          this.$bvToast.toast(this.$t('Saved'), {
            title: this.$t('Notification'),
            autoHideDelay: 5000,
            variant: 'success',
            toaster: 'b-toaster-bottom-right',
          })
        } else {
          this.$bvToast.toast(this.$t('Error'), {
            title: this.$t('Notification'),
            autoHideDelay: 5000,
            variant: 'danger',
            toaster: 'b-toaster-bottom-right',
          })
        }
      })
    },

    async deletingGroup () {
      await this.deleteGroup(this.item.id).then((response) => {
        if (response) {
          this.$bvToast.toast(this.$t('Deleted'), {
            title: this.$t('Notification'),
            autoHideDelay: 5000,
            variant: 'success',
            toaster: 'b-toaster-bottom-right',
          })
        } else {
          this.$bvToast.toast(this.$t('Error'), {
            title: this.$t('Notification'),
            autoHideDelay: 5000,
            variant: 'danger',
            toaster: 'b-toaster-bottom-right',
          })
        }
      })
    },
  },

  watch: {
    item: function () {
      if (!this.showModal) {
        this.titleGroup = this.item.title
        this.selected = this.item.traderAccounts
      }
    },
  },

  created () {
    if (this.item.link != null) {
      this.publicGroup = this.item.link.isPublic == '1'
      this.keyGroup = this.item.link.url
    }
  },

  i18n: {
    messages: {
      'en': {
        'Setting group': 'Setting group',
        'Save': 'Save',
        'Saved': 'Saved',
        'Deleted': 'Deleted',
        'Notification': 'Notification',
        'Error': 'Error',
      },
      'ru': {
        'Setting group': 'Настройки группы',
        'Save': 'Сохранить',
        'Saved': 'Сохранено',
        'Deleted': 'Удалено',
        'Notification': 'Уведомление',
        'Error': 'Ошибка',
      },
    },
  },
}
</script>

<style lang="scss" scoped>
.cursor-pointer {
  cursor: pointer;
}
</style>
