<template>
  <div class="border bg-light shadow p-1 pr-2">
    <div class="table-orders">
      <b-table striped
               hover
               class="table-orders"
               :items="itemsTable"
               :fields="fields"
      >
        <template #head(icon)>
          <div class="d-flex w-100 h-100">
            <Info width="20"/>
          </div>
        </template>
        <template #head(chart)>
          <div class="d-flex w-100 h-100">
            <Info width="20"/>
          </div>
        </template>
        <template #head(comment)>
          <div class="d-flex w-100 h-100">
            <Info width="20"/>
          </div>
        </template>

        <template #cell(icon)>
          <OrderSymbol width="20"/>
        </template>
        <template #cell(symbol)="data">
          <u>{{ data.item.symbol }}</u>
        </template>
        <template #cell(Pips)="data">
          <div>{{ data.item.Pips }}</div>
        </template>
      </b-table>
    </div>
    <div class="mt-3">
      <b-button-group size="sm">
        <b-button @click="prevPage">
          &#10094;
        </b-button>
        <b-form-input v-model="page"/>
        <b-button @click="nextPage">
          &#10097;
        </b-button>
      </b-button-group>
    </div>
  </div>
</template>

<script>
import Info from '@/assets/icons/info.svg'
import OrderSymbol from '@/assets/icons/order_symbol.svg'
import { mapActions, mapState } from 'vuex'

export default {
  name: 'OrdersRobot',
  components: {
    Info,
    OrderSymbol,
  },

  props: {
    robotId: {
      default: '',
    },
  },

  data () {
    return {
      page: 1,
    }
  },
  watch: {
    async page (value) {
      await this.getOrders([this.robotId, this.page])
    },
  },
  computed: {
    ...mapState('chart', [
      'charts',
      'orders',
      'lastPage',
      'times',
      'item',
    ]),

    fields () {
      return [
        { key: 'icon', label: this.$t('icon') },
        { key: 'open_time', label: this.$t('Opening date') },
        { key: 'close_time', label: this.$t('Closing date') },
        { key: 'symbol', label: this.$t('Symbol') },
        { key: 'typeTitle', label: this.$t('Type of operation') },
        { key: 'volume', label: this.$t('Lots') },
        { key: 'SL', label: this.$t('SL (Price)') },
        { key: 'take_profit', label: this.$t('TP (Price)') },
        { key: 'open_price', label: this.$t('Opening price') },
        { key: 'close_price', label: this.$t('Closing price') },
        { key: 'Pips', label: this.$t('Pips') },
        { key: 'profit_', label: this.$t('Net profit') },
        { key: 'duration', label: this.$t('Duration') },
        { key: 'profit', label: this.$t('Profit') },
        { key: 'chart', label: this.$t('Chart') },
        { key: 'comment', label: this.$t('Comment') },
      ]
    },

    itemsTable () {
      return this.orders.map((item) => {
        return {
          isActive: true,
          icon: '',
          open_time: item.open_time,
          close_time: item.close_time,
          symbol: item.symbol,
          typeTitle: item.typeTitle,
          volume: item.volume,
          SL: item.stop_loss,
          take_profit: item.take_profit,
          open_price: item.open_price,
          close_price: item.close_price,
          Pips: '-',
          profit_: item.profit,
          duration: (item.duration != '-') ? (((item.days != '-') ? item.days + this.$t('d ') : '') +
            ((item.hours != '-') ? item.hours + this.$t('h ') : '') +
            ((item.minutes != '-') ? item.minutes + this.$t('m ') : '') +
            ((item.seconds != '-') ? item.seconds + this.$t('s ') : '')) : '-',
          profit: '-',
        }
      })
    },
  },

  methods: {
    ...mapActions('chart', [
      'getOrders',
    ]),

    nextPage () {
      if (parseInt(this.lastPage) > parseInt(this.page)) {
        this.page = parseInt(this.page) + 1
      }
    },

    prevPage () {
      if (parseInt(this.page) - 1 > 0) {
        this.page = parseInt(this.page) - 1
      }
    },
  },

  async created () {
    await this.getOrders([this.robotId, this.page])
  },
}
</script>

<style lang="scss" scoped>
.table-orders {
  overflow-x: auto;
}
</style>
