<template>
  <VAuthContainer>
    <div class="text-5 mb-4">
      {{ $t('SignIn') }}
    </div>

    <form
        novalidate
        class="sign-in-form"
        @submit.prevent="auth"
    >
      <VInput
          v-for="input in inputs"
          :key="input.name"
          :name="input.nameCamelCase"
          :type="input.type"
          :placeholder="$t(input.name)"
          :value="form[input.nameCamelCase]"
          :generalError="rulesForGeneralError"
          :disabled="inputDisabled"
          :required="input.required"
          class="sign-in-form__input"
          @change-input-value="changeForm($event, input.nameCamelCase)"
          @input-errors="onInputErrors"
          @show-error="onBlurInputWithError"
          @hide-error="onFocusInputWithError"
      >
        <component
            v-if="input.icon"
            :is="input.icon"
        />
      </VInput>

      <ErrorsLog
          :errorsShowed="errorsShowed"
          :errors="errors"
          :generalError="generalError"
          :booleanRulesForGeneralError="booleanRulesForGeneralError"
          @error-enter="erorrEnter"
          @error-after-leave="errorAfterLeave"
          @general-error-enter="generalErrorEnter"
          @general-error-after-leave="generalErrorAfterLeave"
      />


      <VLink
        to="reset-password"
        class="mb-3"
      >
        {{ $t('Forgot password?') }}
      </VLink>

      <VButton :isLoading="isLoading"
                variant="primary"
      >
        {{ $t('Log in') }}
      </VButton>
    </form>
  </VAuthContainer>
</template>

<script>
import { mapActions } from 'vuex'

import { validate } from '@/utils/validate'

import { errorsLog } from '@/mixins/errorsLog'
import { authForms } from '@/mixins/authForms'

import VAuthContainer from '@/components/ui/Auth/VAuthContainer'
import VInput from '@/components/ui/Auth/VInput'
import VLink from '@/components/ui/Auth/VLink'
import VButton from '@/components/ui/Auth/VButton'
import ErrorsLog from '@/components/auth/ErrorsLog'

import IconEmail from '@/assets/icons/contact-us.svg'
import IconLock from '@/assets/icons/lock9.svg'

const formTemplate = {
  username: null,
  password: null,
  remember: true,
}

export default {
  name: 'SignInForm',
  components: {
    ErrorsLog,
    VAuthContainer,
    VInput,
    VLink,
    VButton,
    IconEmail,
    IconLock,
  },
  mixins: [
    errorsLog,
    authForms,
  ],

  data: () => ({
    pending: false,
    form: { ...formTemplate },
    inputs: [
      {
        name: 'Email',
        nameCamelCase: 'username',
        type: 'username',
        icon: '', /*IconEmail*/
        required: true,
      },
      {
        name: 'Password',
        nameCamelCase: 'password',
        type: 'password',
        icon: '', /*IconLock*/
        required: true,
      },
    ],
  }),
  computed: {
    statusAllFields () {
      return this.form.username &&
          this.form.password &&
          this.generalError.text === validate.generalErrors.allHighlightedFieldsMustBeFilled
    },
    formIsValid () {
      return this.form.username &&
          this.form.password &&
          !this.errors.length
    },
  },

  methods: {
    ...mapActions('auth', [
      'signIn',
      'signInSuccess',
    ]),

    async auth () {
      if (!this.formIsValid) {
        return this.setGeneralError(true, validate.generalErrors.allHighlightedFieldsMustBeFilled)
      }
      this.setFormLoadingState(true)
      try {
        const response = await this.signIn(this.form)
        this.setFormLoadingState(false)
        this.clearErrors()

        if (!response.data.success) {
          this.setGeneralError(true, validate.generalErrors.tryAgain)
        }

        this.signInSuccess(response.data.data)
      } catch (e) {
        this.setFormLoadingState(false)

        const error = e.response
          ? e.response.data.message
          : e.message

        switch (error) {
          case 'Network Error':
            this.setGeneralError(true, validate.generalErrors.connectionFalse)
            break
          case 'The given data was invalid.':
            this.setGeneralError(true, validate.generalErrors.invalidEmailOrPassword)
            break
          default:
            this.setGeneralError(true, validate.generalErrors.tryAgain)
        }
      }
    },
  },

  metaInfo () {
    return {
      title: this.$t('Sign In'),
    }
  },
  i18n: {
    messages: {
      'en': {
        Email: 'Email',
        Password: 'Password',
        'Log in': 'Log in',
        'Sign In': 'Sign In',
      },
      'ru': {
        'SignIn': 'Вход',
        'Forgot password?': 'Забыли пароль?',
        Email: 'Электронная почта',
        Password: 'Пароль',
        'Log in': 'Войти',
        'Sign In': 'Вход',
      },
    },
  },
}
</script>

<style lang="scss" scoped>
.sign-in-form {
  display: grid;
}

.sign-in-form__input {
  margin-bottom: 20px;
}
</style>
