import { mapActions } from 'vuex'
import { languages } from '@/i18n/languages'

export const defineLanguage = {
  created () {
    const url = new URL(window.location.href)
    const urlLang = url.searchParams.get('lang')

    if (urlLang) {
      this.setLanguage(urlLang)
    } else if (localStorage.getItem('language')) {
      this.setLanguageFromStorage()
    } else {
      const browserLang = window.navigator
        ? (window.navigator.language ||
          window.navigator.systemLanguage ||
          window.navigator.userLanguage)
        : 'en'

      const lang = languages.find(language => language.code.includes(browserLang)).code ||
        'en'
      this.setLanguage(lang)
    }
  },

  methods: {
    ...mapActions('general', [
      'setLanguage',
      'setLanguageFromStorage',
    ]),
  },
}
