<template>
  <Modal
          :label="label"
          :showModal="showModal"
          @update:showModal="$emit('update:showModal', $event)"
          size="lg"
  >
    <template #content>
      <b-form>
        <b-form-group
          id="input-group-2"
          :label="$t('Scopes')"
          label-for="input-scopes"
        >
          <b-form-select v-model="selected"
                         id="input-scopes"
                         :options="robotsOptions"
                         value-field="id"
                         text-field="title"
          />
        </b-form-group>
        <b-form-group
          id="input-group-2"
          :label="$t('Parameter')"
          label-for="input-scopes"
        >
          <b-form-select v-model="selectedParameter"
                         id="input-scopes"
                         :options="selectValues.parameter"
          />
        </b-form-group>
        <b-form-group
          id="input-group-3"
          :label="$t('Direction')"
          label-for="input-direction"
        >
          <b-form-select v-model="selectedDirection"
                         id="input-direction"
                         :options="selectValues.direction"
          />
        </b-form-group>
        <b-form-group
          id="input-group-4"
          :label="$t('Value')"
          label-for="input-value"
        >
          <b-form-input
            id="input-value"
            type="number"
            v-model="valueNotify"
          />
        </b-form-group>
        <b-form-group
          id="input-group-valueStop"
          :label="$t('Up to the value')"
          label-for="input-valueStop"
        >
          <b-form-input
            id="input-valueStop"
            type="number"
            v-model="valueStopNotify"
          />
        </b-form-group>
        <b-form-group
          id="input-group-5"
          :label="$t('Step (Leave blank for one notification)')"
          label-for="input-step"
        >
          <b-form-input
            id="input-step"
            type="number"
            v-model="step"
          />
        </b-form-group>
        <b-form-group
          id="input-group-color"
          :label="$t('Direction')"
          label-for="input-color"
        >
          <b-form-select v-model="color"
                         id="input-color"
                         :options="selectValues.color"
          />
        </b-form-group>

        <b-form-group :label="$t('Notification Delivery Channels')"
                      label-size="lg"
        >
<!--          <b-form-group-->
<!--            id="input-group-6"-->
<!--            label-cols="4" label-cols-lg="2"-->
<!--            :label="$t('Android push')"-->
<!--            label-for="input-android"-->
<!--          >-->
<!--            <b-input-group class="mb-2">-->
<!--              <b-input-group-prepend is-text>-->
<!--                <input type="checkbox">-->
<!--              </b-input-group-prepend>-->
<!--              <b-form-input :placeholder="$t('Number of minutes between notifications')"/>-->
<!--            </b-input-group>-->
<!--          </b-form-group>-->
          <b-form-group
            id="input-group-7"
            label-cols="4" label-cols-lg="2"
            :label="$t('Telegram')"
            label-for="input-telegram"
          >
            <b-input-group class="mb-2">
              <b-input-group-prepend is-text>
                <input type="checkbox"
                       v-model="tg"
                >
              </b-input-group-prepend>
              <b-form-input :placeholder="$t('Number of minutes between notifications')"
                            v-model="tgTimeOut"
              />
            </b-input-group>
          </b-form-group>
          <b-form-group
            id="input-group-8"
            label-cols="4" label-cols-lg="2"
            :label="$t('E-mail')"
            label-for="input-telegram"
          >
            <b-input-group class="mb-2">
              <b-input-group-prepend is-text>
                <input type="checkbox"
                       v-model="email"
                >
              </b-input-group-prepend>
              <b-form-input :placeholder="$t('Number of minutes between notifications')"
                            v-model="emailTimeOut"
              />
            </b-input-group>
          </b-form-group>
        </b-form-group>

        <b-form-group
          id="input-group-status"
          :label="$t('Status')"
          label-for="input-status"
        >
          <b-form-select v-model="status"
                         id="input-status"
                         :options="selectValues.status"
          />
        </b-form-group>
      </b-form>
    </template>

    <template #footer>
      <div class="w-100">
        <b-button
          size="sm"
          class="float-right ml-2"
          variant="primary"
          id="save-button"
          @click="action"
        >
          {{ labelOK }}
        </b-button>

        <b-button
          size="sm"
          class="float-right"
          @click="$emit('update:showModal', false)"
        >
          {{$t('Close')}}
        </b-button>

      </div>
    </template>
  </Modal>
</template>

<script>
import Modal from '../../ui/Modal'
import { mapActions, mapState } from 'vuex'
import LinkCopy from '@/assets/icons/copy.svg'

export default {
  name: 'SettingGroup',
  components: {
    Modal,
    LinkCopy,
  },

  props: [
    'showModal',
    'label',
    'labelOK',
    'create',
    'item',
  ],

  data () {
    return {
      selected: null,
      selectedParameter: 'balance',
      selectedDirection: 'more',
      valueNotify: 1,
      valueStopNotify: 100,
      step: 1,
      color: 'warning',
      tg: 0,
      tgTimeOut: '',
      email: 0,
      emailTimeOut: '',
      status: 'active',
    }
  },

  computed: {
    ...mapState('robot', [
      'robots',
    ]),
    ...mapState('notification', [
      'selectValues',
    ]),

    robotsOptions () {
      return [
        {
          title: this.$t('All scopes'),
          id: null,
        },
        ...this.robots.map((item) => {
          return ({
            title: item.title,
            id: item.id,
          })
        }),
      ]
    },
  },

  methods: {
    ...mapActions('robot', [
      'getRobots',
    ]),
    ...mapActions('notification', [
      'getSelectValues',
      'createNotify',
      'updateNotify',
    ]),

    async action () {
      if (this.create) {
        await this.createNotify({
          traderId: this.selected,
          parameter: this.selectedParameter,
          direction: this.selectedDirection,
          value: this.valueNotify,
          valueStop: this.valueStopNotify,
          step: this.step,
          color: this.color,
          tg: this.tg,
          tgTimeOut: this.tgTimeOut,
          email: this.email,
          emailTimeOut: this.emailTimeOut,
          status: this.status,
        }).then((response) => {
          if (response) {
            this.$bvToast.toast(this.$t('Saved'), {
              title: this.$t('Notification'),
              autoHideDelay: 5000,
              variant: 'success',
              toaster: 'b-toaster-bottom-right',
            })
          } else {
            this.$bvToast.toast(this.$t('Error'), {
              title: this.$t('Notification'),
              autoHideDelay: 5000,
              variant: 'danger',
              toaster: 'b-toaster-bottom-right',
            })
          }
        })
      } else {
        await this.updateNotify([
          this.item.id,
          {
            traderId: this.selected,
            parameter: this.selectedParameter,
            direction: this.selectedDirection,
            value: this.valueNotify,
            valueStop: this.valueStopNotify,
            step: this.step,
            color: this.color,
            tg: this.tg,
            tgTimeOut: this.tgTimeOut,
            email: this.email,
            emailTimeOut: this.emailTimeOut,
            status: this.status,
          },
        ]).then((response) => {
          if (response) {
            this.$bvToast.toast(this.$t('Saved'), {
              title: this.$t('Notification'),
              autoHideDelay: 5000,
              variant: 'success',
              toaster: 'b-toaster-bottom-right',
            })
          } else {
            this.$bvToast.toast(this.$t('Error'), {
              title: this.$t('Notification'),
              autoHideDelay: 5000,
              variant: 'danger',
              toaster: 'b-toaster-bottom-right',
            })
          }
        })
      }
    },

    fallbackCopyTextToClipboard (text) {
      var textArea = document.createElement('textarea')
      textArea.value = text

      // Avoid scrolling to bottom
      textArea.style.top = '0'
      textArea.style.left = '0'
      textArea.style.position = 'fixed'

      document.body.appendChild(textArea)
      textArea.focus()
      textArea.select()

      try {
        document.execCommand('copy')

        this.$bvToast.toast(this.$t('Copied'), {
          title: this.$t('Notification'),
          autoHideDelay: 5000,
          variant: 'success',
          toaster: 'b-toaster-bottom-right',
        })
      } catch (err) {
        console.error('Fallback: Oops, unable to copy', err)
      }

      document.body.removeChild(textArea)
    },

    copyTextToClipboard (text) {
      if (!navigator.clipboard) {
        this.fallbackCopyTextToClipboard(text)
        return
      }
      navigator.clipboard.writeText(text).then(function () {
        this.$bvToast.toast(this.$t('Copied'), {
          title: this.$t('Notification'),
          autoHideDelay: 5000,
          variant: 'success',
          toaster: 'b-toaster-bottom-right',
        })
      }, function (err) {
        console.error('Async: Could not copy text: ', err)
      })
    },
  },

  created: async function () {
    await this.getRobots()
    await this.getSelectValues()
    if (this.create == false) {
      this.selected = this.item.traderAccount
      this.selectedParameter = this.item.parameter
      this.selectedDirection = this.item.direction
      this.valueNotify = this.item.value
      this.valueStopNotify = this.item.valueStop
      this.step = this.item.step
      this.color = this.item.color
      this.tg = this.item.tg
      this.tgTimeOut = this.item.tgTimeout
      this.email = this.item.email
      this.emailTimeOut = this.item.emailTimeout
      this.status = (this.item.status == 'Активный') ? 'active' : 'inactive'
    }
  },

  i18n: {
    messages: {
      'ru': {
        'All scopes': 'Все счета',
        'scope selected': ' счет выбран',
        'Scopes': 'Счета',
        'Parameter': 'Параметр',
        'Direction': 'Тренд',
        'Value': 'Значение',
        'Up to the value': 'До значения',
        'Step (Leave blank for one notification)': 'Шаг (Для одного уведомления оставьте пустым)',
        'Notification Delivery Channels': 'Каналы доставки уведомлений',
        'Number of minutes between notifications': 'Кол-во минут между уведомлениями',
        'Status': 'Статус',
        'Close': 'Закрыть',
      },
    },
  },
}
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>

<style scoped>
.icon {
  width: 22px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 10px;
  cursor: pointer;

  svg {
    width: 22px;
    height: 22px;
    fill: $grey;
    display: block;
    cursor: pointer;
  }
}

</style>
