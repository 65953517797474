// import { useKriptid } from '@/utils/helpers'
import { requestRobots, requestDeleteRobot, requestUpdateRobot, requestRobotsByGroup } from '@/requestManager/RobotRequests'
import Vue from 'vue'

const state = {
  robots: [],
  isTabActive: true,
  timezone: null,
}

const getters = {
  hasCompletedTip: state => robots => {
    return ''
  },
}

const mutations = {
  SET_ROBOTS_DATA (state, robots) {
    state.robots = robots
  },

  SET_ROBOT_DATA (state, robot) {
    var index_
    state.robots.some(function (item, index) {
      index_ = index
      return item.id == robot.id
    })
    Vue.set(state.robots, index_, robot)
  },

  DELETE_ROBOT_DATA (state, index) {
    if (state.robots.length != 0) {
      var index_ = -1
      state.robots.some(function (item, index) {
        index_ = index
        return item.id == index
      })
      if (index_ != -1) {
        state.robots.splice(index_, 1)
        state.robots = state.robots
      }
    }
  },
}

const actions = {
  async getRobots ({ state, commit, dispatch }) {
    const response = await requestRobots()

    if ((response.data.success)) {
      const resp = await response.data.data.map(item => {
        if (item.tradingInfo != null) {
          item.tradingInfo.typeBuy = 'Sell'
          item.tradingInfo.profitCent = (item.tradingInfo.profit * 100 / item.tradingInfo.balance).toFixed(2)
          if (!isFinite(item.tradingInfo.profitCent)) {
            item.tradingInfo.profitCent = 0
          }
          item.tradingInfo.profitDayCent = (item.tradingInfo.profitDay * 100 / item.tradingInfo.balance).toFixed(2)
          if (!isFinite(item.tradingInfo.profitDayCent)) {
            item.tradingInfo.profitDayCent = 0
          }
          item.tradingInfo.profitWeekCent = (item.tradingInfo.profitWeek * 100 / item.tradingInfo.balance).toFixed(2)
          if (!isFinite(item.tradingInfo.profitWeekCent)) {
            item.tradingInfo.profitWeekCent = 0
          }
          item.tradingInfo.profitMonthCent = (item.tradingInfo.profitMonth * 100 / item.tradingInfo.balance).toFixed(2)
          if (!isFinite(item.tradingInfo.profitMonthCent)) {
            item.tradingInfo.profitMonthCent = 0
          }
          item.lastUpdate = (new Date(item.tradingInfo.lastUpdate)).toLocaleDateString() + ' | ' + (new Date(item.tradingInfo.lastUpdate)).toLocaleTimeString([], {
            hour: '2-digit',
            minute: '2-digit',
            second: '2-digit',
          })
        }
        return item
      })

      commit('SET_ROBOTS_DATA', resp)
    }

    return response.data
  },

  async getRobotsByGroup ({ state, commit, dispatch }, index) {
    const response = await requestRobotsByGroup(index)

    if ((response.data.success)) {
      const resp = await response.data.data.filter(function (item) {
        if ((item.tradingInfo != null) && (item.title != null)) {
          return true
        }
      }).map(item => {
        item.tradingInfo.typeBuy = 'Sell'
        item.tradingInfo.profitCent = (item.tradingInfo.profit * 100 / item.tradingInfo.balance).toFixed(2)
        if (!isFinite(item.tradingInfo.profitCent)) {
          item.tradingInfo.profitCent = 0
        }
        item.tradingInfo.profitDayCent = (item.tradingInfo.profitDay * 100 / item.tradingInfo.balance).toFixed(2)
        if (!isFinite(item.tradingInfo.profitDayCent)) {
          item.tradingInfo.profitDayCent = 0
        }
        item.tradingInfo.profitWeekCent = (item.tradingInfo.profitWeek * 100 / item.tradingInfo.balance).toFixed(2)
        if (!isFinite(item.tradingInfo.profitWeekCent)) {
          item.tradingInfo.profitWeekCent = 0
        }
        item.tradingInfo.profitMonthCent = (item.tradingInfo.profitMonth * 100 / item.tradingInfo.balance).toFixed(2)
        if (!isFinite(item.tradingInfo.profitMonthCent)) {
          item.tradingInfo.profitMonthCent = 0
        }
        item.lastUpdate = (new Date(item.tradingInfo.lastUpdate)).toLocaleDateString() + ' | ' + (new Date(item.tradingInfo.lastUpdate)).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', second: '2-digit' })
        return item
      })

      commit('SET_ROBOTS_DATA', resp)
    }

    return response.data
  },

  async deleteRobot ({ state, commit, dispatch }, index) {
    const response = await requestDeleteRobot(index)

    const resp = await response.data
    if (resp.success) {
      commit('DELETE_ROBOT_DATA', resp.data)
    }
    return resp.success
  },

  async updateRobot ({ state, commit, dispatch }, params) {
    const response = await requestUpdateRobot(params[0], {
      'title': params[1],
      'is_public': params[2] ? 1 : 0,
      'link': params[3],
      'real': params[4] ? 1 : 0,
      'robotNotification': params[5] ? 1 : 0,
    })

    const resp = await response.data
    resp.data.lastUpdate = (new Date(resp.data.tradingInfo.lastUpdate)).toLocaleDateString() + ' | ' + (new Date(resp.data.tradingInfo.lastUpdate)).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', second: '2-digit' })
    if (resp.success) {
      commit('SET_ROBOT_DATA', resp.data)
    }
    return resp.success
  },
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
}
