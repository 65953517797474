import { requestGet, requestGetPublicKey, requestCheckPublicKey } from '@/requestManager/PublicRequests'
//import Vue from 'vue'

const state = {
  publicItem: [],
  title: '',
  type: '',
  piblicKey: '',
  isTabActive: true,
  timezone: null,
}

const getters = {
  hasCompletedTip: state => robots => {
    return ''
  },
}

const mutations = {
  SET_PUBLIC_DATA (state, publicItem) {
    state.type = publicItem.type
    state.title = ''
    if (state.type == 'group') {
      state.title = publicItem.group.title
      state.publicItem = publicItem.data.filter(function (item) {
        if ((item.tradingInfo != null) && (item.title != null)) {
          return true
        }
      }).map(item => {
        item.tradingInfo.typeBuy = 'Sell'
        item.tradingInfo.profitCent = (item.tradingInfo.profit * 100 / item.tradingInfo.balance).toFixed(2)
        if (!isFinite(item.tradingInfo.profitCent)) {
          item.tradingInfo.profitCent = 0
        }
        item.tradingInfo.profitDayCent = (item.tradingInfo.profitDay * 100 / item.tradingInfo.balance).toFixed(2)
        if (!isFinite(item.tradingInfo.profitDayCent)) {
          item.tradingInfo.profitDayCent = 0
        }
        item.tradingInfo.profitWeekCent = (item.tradingInfo.profitWeek * 100 / item.tradingInfo.balance).toFixed(2)
        if (!isFinite(item.tradingInfo.profitWeekCent)) {
          item.tradingInfo.profitWeekCent = 0
        }
        item.tradingInfo.profitMonthCent = (item.tradingInfo.profitMonth * 100 / item.tradingInfo.balance).toFixed(2)
        if (!isFinite(item.tradingInfo.profitMonthCent)) {
          item.tradingInfo.profitMonthCent = 0
        }
        item.lastUpdate = (new Date(item.tradingInfo.lastUpdate)).toLocaleDateString() + ' | ' + (new Date(item.tradingInfo.lastUpdate)).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', second: '2-digit' })
        return item
      })
    } else {
      const item = publicItem.data
      if ((item.tradingInfo != null) && (item.title != null)) {
        item.tradingInfo.typeBuy = 'Sell'
        item.tradingInfo.profitCent = (item.tradingInfo.profit * 100 / item.tradingInfo.balance).toFixed(2)
        if (!isFinite(item.tradingInfo.profitCent)) {
          item.tradingInfo.profitCent = 0
        }
        item.tradingInfo.profitDayCent = (item.tradingInfo.profitDay * 100 / item.tradingInfo.balance).toFixed(2)
        if (!isFinite(item.tradingInfo.profitDayCent)) {
          item.tradingInfo.profitDayCent = 0
        }
        item.tradingInfo.profitWeekCent = (item.tradingInfo.profitWeek * 100 / item.tradingInfo.balance).toFixed(2)
        if (!isFinite(item.tradingInfo.profitWeekCent)) {
          item.tradingInfo.profitWeekCent = 0
        }
        item.tradingInfo.profitMonthCent = (item.tradingInfo.profitMonth * 100 / item.tradingInfo.balance).toFixed(2)
        if (!isFinite(item.tradingInfo.profitMonthCent)) {
          item.tradingInfo.profitMonthCent = 0
        }
        item.lastUpdate = (new Date(item.tradingInfo.lastUpdate)).toLocaleDateString() + ' | ' + (new Date(item.tradingInfo.lastUpdate)).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', second: '2-digit' })
      }
      state.publicItem = item
    }
  },

  SET_PUBLIC_KEY_DATA (state, key) {
    state.publicKey = key
  },
}

const actions = {
  async getPublic ({ state, commit, dispatch }, key) {
    const response = await requestGet(key)

    if ((response.data.success)) {
      const resp = await response.data
      commit('SET_PUBLIC_DATA', resp)
    }

    return response.data.success
  },

  async getPublicKey ({ state, commit, dispatch }, type, params) {
    const response = await requestGetPublicKey(type)
    const resp = response.data
    commit('SET_PUBLIC_KEY_DATA', resp.data.link)
    return resp.success
  },

  async checkPublicKey ({ state, commit, dispatch }, params) {
    const response = await requestCheckPublicKey(params[0], params[1], params[2])
    return response.data.success
  },
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
}
