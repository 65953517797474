<template>
  <div class="home">
    <div class="d-flex flex-column flex-md-row flex-wrap justify-content-between m-0 ml-md-3 p-0 mt-5">
        <div class="container jumbotron mt-0 pb-2 pt-4 shadow-sm mx-1 col-12 col-md-5_"
             v-for="item in expertTypes"
        >
          <div class="d-flex text-center justify-content-center mt-1 mb-1"
          >
            <div class="text-4 font-weight-bold cursor-pointer">
              {{item.title}}
            </div>
          </div>

          <template v-if="item.expertChangelogs[0]">
            <template v-for="changeLog in item.expertChangelogs">
              <div class="d-flex justify-content-start flex-column align-items-start text-left">
                <u>
                  {{ $t('Update') + ' v' + changeLog.version }}
                </u>
                <template v-for="item in changeLog.description.blocks">
                  <p v-if="item.type == 'paragraph'"
                     v-html="item.data.text"
                  />
                  <v-img v-else-if="item.type == 'image'"
                         :src="item.data.file.url"
                  />
                  <ul v-if="item.type == 'list'">
                    <li v-for="item in item.data.items"
                       v-html="item"
                    />
                  </ul>
                </template>
                <div>
                  <a class="d-flex justify-content-between align-items-center"
                     :href="changeLog.file"
                  >
                    <div class="imgFile">
                      <IconFile/>
                    </div>
                    {{ $t('file') }}
                  </a>
                </div>
              </div>

    <!--          <div class="d-flex flex-column align-content-center justify-content-center">-->
    <!--            {{ $t('No data from the terminal.' )}}-->
    <!--            <p>-->
    <!--              {{ $t('Check the functionality of the terminal and the correct installation of the Expert Advisor') }}-->
    <!--            </p>-->
    <!--          </div>-->

              <div class="text-right mt-4 mb-4">
                {{ changeLog.createdAt }}
              </div>
            </template>
          </template>
          <template v-else>
            <div class="mb-5 d-flex justify-content-center align-items-center h-100">
              {{ $t('No data available') }}
            </div>
          </template>
        </div>
    </div>
  </div>
</template>

<script>
import IconFile from '@/assets/icons/file.svg'
import { mapActions, mapState } from 'vuex'

export default {
  name: 'Index',

  components: {
    IconFile,
  },

  computed: {
    ...mapState('advisors', [
      'expertTypes',
    ]),
  },

  methods: {
    ...mapActions('advisors', [
      'getExpertTypes',
    ]),
    ...mapActions('general', [
      'setLoading',
    ]),
  },

  created: async function () {
    this.getExpertTypes()
    this.time = new Date().getTime()

    this.intervalGetRobots = setInterval(async () => {
      await this.getExpertTypes()
      this.time = new Date().getTime()
    }, 10000)

    // this.setLastTime()
    // this.intervalLastTime = setInterval(() => {
    //   this.setLastTime()
    // }, 4000)
    this.setLoading(false)
  },

  beforeDestroy () {
    if (this.intervalGetRobots) clearInterval(this.intervalGetRobots)
    // if (this.intervalLastTime) clearInterval(this.intervalLastTime)
  },
}
</script>

<style scoped>
.home {
  overflow: auto;
  overflow-x:hidden
}

@media (min-width: 1200px) {
  .col-md-5_ {
    flex: 0 0 48%;
    max-width: 48%;
  }
}

.imgFile {
  height: 24px;
  margin-right: 10px;
  svg {
    width: auto;
    height: 15px;
  }
}
</style>
