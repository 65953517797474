<template>
  <div>
    <div class="d-flex justify-content-between mb-1 flex-lg-row mb-0 pb-0">
      <div class="d-flex flex-column">
        <div>
          <p class="mb-1 text-primary font-weight-bold text-4 d-flex align-content-start">
            $ {{item.tradingInfo.balance}}
          </p>
        </div>
        <div class="d-flex align-content-center flex-row mb-0">
          <p class="">
            {{ $t('profit') }}
          </p>
          <p class="ml-2 mt-1 text-primary font-weight-bold text-4">
            {{item.tradingInfo.profitCent}}%
          </p>
        </div>
        <div class="d-flex align-content-center flex-row">
          <p class="mb-0">
            {{ $t('margin') }}
          </p>
          <p class="ml-2 mt-1 text-primary font-weight-bold text-4 mb-0">
            {{item.tradingInfo.marginLevel}}%
          </p>
        </div>
      </div>

      <div class="mt-1 mr-1">
        <div class="col-7"></div>
        <div class="">
          <p class="mb-1 font-weight-bold font-weight-bold text-5"
             :style="{ color: greenOrRed(item.tradingInfo.profitDay) }"
          >
            $ {{ ((item.tradingInfo.profitDay > 0) ? '+' : '') + item.tradingInfo.profitDay}}
            <span class="font-weight-light">
              ({{item.tradingInfo.profitDayCent}}%)
            </span>
          </p>
          <p class="text-3 mb-1 font-weight-light">
            {{ $t('week') }}
            <span class="text-3"
                  :style="{ color: greenOrRed(item.tradingInfo.profitWeek) }"
            >
              $ {{ ((item.tradingInfo.profitWeek > 0) ? '+' : '') + item.tradingInfo.profitWeek}}
            ({{item.tradingInfo.profitWeekCent}}%)
            </span>
          </p>
          <p class="text-3 mb-0 font-weight-light">
            {{ $t('month') }}
            <span class="text-3"
                  :style="{ color: greenOrRed(item.tradingInfo.profitMonth) }"
            >
              $ {{ ((item.tradingInfo.profitMonth > 0) ? '+' : '') + item.tradingInfo.profitMonth}}
            ({{item.tradingInfo.profitMonthCent}}%)
            </span>
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'CardLite',
  props: [
    'item',
  ],

  methods: {
    greenOrRed (i) {
      return ((i > 0) ? '#35bd77' : (i == 0) ? '' : 'red')
    },
  },

  i18n: {
    messages: {
      'en': {
        'profit': 'profit',
        'margin': 'margin',
        'week': 'week',
        'month': 'month',
      },
      'ru': {
        'profit': 'просадка',
        'margin': 'маржа',
        'week': 'неделя',
        'month': 'месяц',
      },
    },
  },
}
</script>

<style lang="scss" scoped>
</style>
