import { captureException } from '@sentry/browser'
import dayjs from 'dayjs'
dayjs.extend(require('dayjs/plugin/customParseFormat'))
dayjs.extend(require('dayjs/plugin/utc'))

/**
 * Хелпер для плюрализации строк
 *
 * @param {number} count
 * @param {Array<string> | string[]} plurals
 * @returns {string}
 *
 * @example pluralize(1,  ['день', 'дня', 'дней']); => 1 день
 * @example pluralize(2,  ['день', 'дня', 'дней']); => 2 дня
 * @example pluralize(12, ['день', 'дня', 'дней']); => 12 дней
 * @example pluralize(1,  ['day', 'days', 'days']); => 1 day
 * @example pluralize(2,  ['day', 'days', 'days']); => 2 days
 */
export function pluralize (count, plurals) {
  if (typeof count !== 'number') {
    throw new Error('The first arg must be type of number and greater than zero')
  }

  if (!Array.isArray(plurals) || plurals?.length < 3 || plurals.some((v) => typeof v !== 'string')) {
    throw new Error('The second arg must be type of Array<String> with length equals 3')
  }

  const [one, two, five] = plurals
  let n = Math.abs(count)

  n %= 100

  if (n >= 5 && n <= 20) {
    return `${count} ${five}`
  }

  n %= 10

  if (n === 1) {
    return `${count} ${one}`
  }

  if (n >= 2 && n <= 4) {
    return `${count} ${two}`
  }

  return `${count} ${five}`
}

export function encodeGetData (data) {
  return Object.keys(data).map(function (key) {
    return [key, data[key]].map(encodeURIComponent).join('=')
  }).join('&')
}

export function useKriptid (count, eventType, eventName, params) {
  try {
    window.kriptid(count, eventType, eventName, params)
  } catch (e) {
    captureException(e)
  }
}

export function parseDDMMYYYYtoUTC (ddmmyyyy) {
  const timezoneOffset = dayjs().utc().startOf('day').diff(
    dayjs().startOf('day'),
  )

  return dayjs(dayjs(ddmmyyyy, 'DD.MM.YYYY') + timezoneOffset)
}

export function lightenDarkenColor (col, amt) {
  if (!col) {
    return false
  }

  let usePound = false
  if (col[0] === '#') {
    col = col.slice(1)
    usePound = true
  }

  const num = parseInt(col, 16)
  let r = (num >> 16) + amt

  if (r > 255) {
    r = 255
  } else if (r < 0) {
    r = 0
  }

  let b = ((num >> 8) & 0x00FF) + amt

  if (b > 255) {
    b = 255
  } else if (b < 0) {
    b = 0
  }

  let g = (num & 0x0000FF) + amt

  if (g > 255) {
    g = 255
  } else if (g < 0) {
    g = 0
  }

  return (usePound ? '#' : '') + (g | (b << 8) | (r << 16)).toString(16)
}

export function declOfNum (number, titles) {
  const cases = [2, 0, 1, 1, 1, 2]
  return titles[
    (number % 100 > 4 && number % 100 < 20)
      ? 2
      : cases[(number % 10 < 5)
        ? number % 10
        : 5]
  ]
}

export function convertHexToShadow (color, shadowSettings, opacity) {
  if (color) {
    color = color.replace('#', '')
    const r = parseInt(color.substring(0, 2), 16)
    const g = parseInt(color.substring(2, 4), 16)
    const b = parseInt(color.substring(4, 6), 16)
    return `${shadowSettings} rgba(${r},${g},${b},${opacity})`
  }
}
