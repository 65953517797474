<template>
  <div class="v-text-error">
    <IconCheckboxDeleteCircle/>
    <slot/>
  </div>
</template>

<script>
import IconCheckboxDeleteCircle from '@/assets/icons/checkbox-delete-circle.svg'

export default {
  name: 'VTextError',
  components: {
    IconCheckboxDeleteCircle,
  },
}
</script>

<style lang="scss" scoped>
.v-text-error {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-variation-settings: 'wght' 600;
  font-size: 13px;
  line-height: 100%;
  text-align: left;
  color: #FF6666;

  svg {
    margin-right: 5px;
    height: auto;
    width: 20px;

    path {
      fill: #FF6666;
    }
  }
}
</style>
